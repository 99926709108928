import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { LoginDielEnergia } from './LoginDielEnergia';
import { useEffect } from 'react';

interface LoginProps extends Pick<RouteComponentProps, 'history'> {
  setIsLogin: () => void;
  setIsLoading: () => void;
}

const dielEnergiaHost = 'energia.dielenergia.com';

export const Login: React.FC<LoginProps> = ({
  history,
  setIsLogin,
  setIsLoading,
}) => {
  const auth = useAuth();
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const toPage = queryParams.get('topage');

  useEffect(() => {
    if (window.location.href.includes(dielEnergiaHost)) {
      return;
    }

    if (isAuthenticated) {
      if (toPage) {
        history.push(toPage);
      } else {
        history.push('/analise/unidades');
      }
    } else {
      const link = `${window.location.origin}/login${toPage ? `?topage=${toPage}` : ''}`;
      auth.signinRedirect({
        redirect_uri: link,
      });
    }
  }, [isAuthenticated, history, toPage, auth]);

  if (window.location.href.includes(dielEnergiaHost)) {
    return (
      <LoginDielEnergia
        history={history}
        setIsLoading={setIsLoading}
        setIsLogin={setIsLogin}
      />
    );
  }

  return (
    <div>
      <h1>Redirecionando...</h1>
    </div>
  );
};
