import { Flex } from 'reflexbox';
import { HeaderContainer, ItemContainer } from '../styles';
import { StyledLink } from '../../styles';
import { Checkbox } from 'components';
import { useTranslation } from 'react-i18next';
import { ReactElement, useState } from 'react';
import { DriException, DriSchedule, DriScheduleModal } from 'pages/Analysis/SchedulesModals/DRI_ScheduleModal';
import { callDriSchedules } from 'helpers/driSchedule';
import { DevFullInfo } from '~/store';
import { IconWatchButton } from '../../../UnitDetail/UnitDetailDACsDAMs/components/MosaicListItem/components/MachineAutomationFancoil/styles';
import { colors } from '~/styles/colors';
import { WatchIcon } from '~/icons';
import { DriItemList } from '../types';
import { useDriProgrammingContext } from '../context/ProgrammingsContext';
import { generateDefaultDevInfo, splitProgrammingToSchedulesExceptions } from '../constants';
import { useCommonPropsContext } from '../context/CommonPropertiesContext';
import { TextComponentWithTooltip } from './TextComponentWithTooltip';

interface ProgItemProps {
  data: DriItemList[];
  onCheckItem: (id: string) => void;
}

type DriInfo = {
    exceptions: DriException[],
    schedules: DriSchedule[],
    info: DevFullInfo,
    deviceCode: string,
}

interface DriItemProps {
  checked: boolean;
  dri: DriItemList;
  disabledWatch: boolean;
  onClickWatchButton: (dri: DriItemList) => void;
  onCheckItem: (id: string) => void;
}

function DriItem({
  checked,
  dri,
  disabledWatch,
  onClickWatchButton,
  onCheckItem,
}: Readonly<DriItemProps>): ReactElement {
  return (
    <ItemContainer key={dri.DRI_ID.toString()}>
      <Flex>
        <Checkbox
          size={20}
          checked={checked}
          onClick={() => onCheckItem(dri.DRI_ID)}
        />
        <TextComponentWithTooltip
          text={dri.MACHINE_NAME ?? '-'}
          link={dri.MACHINE_ID ? `/analise/maquina/${dri.MACHINE_ID}/ativos` : undefined}
        />
      </Flex>
      <StyledLink
        to={`/analise/dispositivo/${dri.DRI_ID}/informacoes`}
        style={{
          textDecoration: 'underline',
          marginLeft: 0,
        }}
        color="#8b8b8b"
      >
        {dri.DRI_ID ?? '-' }
      </StyledLink>
      <TextComponentWithTooltip
        text={dri.ENVIRONMENT_NAME ?? '-'}
        link={(dri.DUT_CODE && dri.ENVIRONMENT_NAME)
          ? `/analise/dispositivo/${dri.DUT_CODE}/informacoes` : undefined}
      />
      <span>
        {dri.RTYPE_NAME ?? '-' }
      </span>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <IconWatchButton
          onClick={() => onClickWatchButton(dri)}
          disabled={disabledWatch}
        >
          <WatchIcon color={disabledWatch ? colors.Grey : colors.BlueSecondary} />
        </IconWatchButton>
      </div>
    </ItemContainer>
  );
}

export function DriProgItem({
  data,
  onCheckItem,
}: Readonly<ProgItemProps>): ReactElement {
  const { t } = useTranslation();
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [driInfo, setDriInfo] = useState<DriInfo | undefined>();
  const { scheds, fetchScheds } = useDriProgrammingContext();
  const { selectedDevType } = useCommonPropsContext();

  async function onClickWatchButton(dri: DriItemList): Promise<void> {
    if (!scheds[dri.DRI_ID]) {
      await fetchScheds([dri.DRI_ID]);
    }

    const schedsDri = scheds[dri.DRI_ID];

    const { exceptions, schedules } = splitProgrammingToSchedulesExceptions(schedsDri);

    const defaultConfig = generateDefaultDevInfo(1, selectedDevType);
    setDriInfo({
      deviceCode: dri.DRI_ID,
      exceptions,
      schedules,
      info: {
        ...defaultConfig,
        dri: {
          ...defaultConfig.dri,
          ...(dri.VARSCFG && { varsCfg: JSON.parse(dri.VARSCFG) }),
        },
        DEVICE_ID: dri.DRI_ID,
      } as unknown as DevFullInfo,
    });

    setShowScheduleModal(true);
  }

  return (
    <>
      {driInfo && !!scheds[driInfo.deviceCode] && (
        <DriScheduleModal
          devInfo={driInfo.info}
          exceptions={driInfo.exceptions}
          schedules={driInfo.schedules}
          open={showScheduleModal}
          onClose={() => {
            setShowScheduleModal(false);
            setDriInfo(undefined);
          }}
          refetch={() => callDriSchedules(driInfo.deviceCode)}
          hide={{
            add: true,
            edit: true,
            delete: true,
          }}
        />
      )}
      <Flex
        color="#8B8B8B"
        padding="10px"
        flexDirection="column"
        style={{
          minWidth: '978px',
          width: '100%',
          rowGap: '8px',
        }}
      >
        <HeaderContainer>
          <span style={{ marginLeft: '30px' }}>
            {t('maquina')}
          </span>
          <span>
            {t('Dispositivo')}
          </span>
          <span>
            {t('ambienteMonitorado')}
          </span>
          <span>
            {t('tipoAmbiente')}
          </span>
          <span>
            {t('programacao')}
          </span>
        </HeaderContainer>
        <Flex
          flexDirection="column"
          style={{
            rowGap: '6px',
          }}
        >
          {data.map((dri) => (
            <DriItem
              checked={dri.checked}
              dri={dri}
              onCheckItem={onCheckItem}
              onClickWatchButton={onClickWatchButton}
              disabledWatch={!scheds[dri.DRI_ID] || scheds[dri.DRI_ID].length === 0}
              key={dri.DRI_ID.toString()}
            />
          ))}
        </Flex>
      </Flex>
    </>
  );
}
