import styled from 'styled-components';

export const GroupButton = styled.button`
  padding: 10px;
  border: 0px;
  border-radius: 12px;
  background-color: #F0F0F0;
  color: #8B8B8B;
  cursor: pointer;
  display: grid;
  column-gap: 20px;
  grid-template-columns: 20px 80px 150px 300px 1fr 20px;
  align-items: center;
  justify-content: start;
`;

export const HeaderSpan = styled.span`
  font-weight: bold;
  margin-left: 30px;
  font-size: 12px;
`;

export const TooltipSpan = styled.span`
  font-size: 95%;
`;

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 40%) 160px 200px 200px 80px 20px;
  align-items: center;
  justify-content: start;
  column-gap: 20px;
  min-width: 978px;
  width: 100%;

  span {
    font-weight: bold;
    font-size: 12px;
  }
`;

export const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(200px, 40%) 160px 200px 200px 80px 20px;
  align-items: center;
  justify-content: start;
  column-gap: 20px;
  min-width: 978px;
  width: 100%;
`;
