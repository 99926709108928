export const AutomaticMode = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="23"
    fill="none"
    viewBox="0 0 23 23"
  >
    <path
      fill="#00D698"
      d="M12.643 11.019c-1.966.512-3.733 1.939-4.728 3.69-.24.424-.489.93-.614 1.395-.052.063-.295-.202-.34-.254-1.336-1.52-1.423-3.861-.114-5.424C7.93 9.13 9.834 9.242 11.162 8.332c1.183-.809 1.944-2.258 2.107-3.668.031-.267-.104-.878.395-.678.185.073.49.451.626.62 3.084 3.789 2.23 11.127-2.783 12.884l-1.081.169c.079.232.055.471.143.706.686 1.85 3.8 1.79 5.312 1.158 5.02-2.097 6.231-8.817 3.206-13.076-5.07-7.141-16.333-3.865-16.845 4.824-.027.46.013.914.063 1.367a1.116 1.116 0 0 1-.856 1.205 1.123 1.123 0 0 1-1.372-.924l-.05-.345c-.545-8.173 7.5-14.389 15.358-11.6 7.362 2.613 10.08 11.799 5.042 17.87-2.359 2.844-7.113 4.629-10.394 2.256-3.834-2.772-.937-7.218 1.842-9.46.252-.203.577-.36.768-.62z"
    />
  </svg>
);
