import {
  Automatic, Refrigerate, ChillerIcon,
  AtivoSemMonitIcon,
  SplitPisoTetoIcon,
  SplitCasseteIcon,
  SplitHiWallIcon,
  SplitaoIcon,
  SelfIcon,
  EvaporatorIcon,
  CondenserIcon,
  FancoilIcon,
  BlockIcon,
  VentIcon,
} from '~/icons';

export const iconsModes = {
  Automatico: <Automatic />,
  Manual: '',
};

export const iconsStatesMachine = {
  ECO: {
    name: 'refrigerar',
    icon: <Refrigerate />,
  },
  COOL: {
    name: 'refrigerar',
    icon: <Refrigerate />,
  },
  DISABLED: {
    name: 'desligado',
    icon: <BlockIcon width={12} height={12} color="rgba(156, 156, 156, 1)" />,
  },
  FAN: {
    name: 'ventilar',
    icon: <VentIcon width={14} height={14} color="rgba(156, 156, 156, 1)" />,
  },
};

export function returnTempAlert(temp, tmin, tmax) {
  if ((tmin != null) && (temp < tmin)) {
    return 'low';
  }
  if ((tmax != null) && (temp > tmax)) {
    return 'high';
  }
  return 'good';
}

export const iconsMachineTypes = {
  chiller: <ChillerIcon width={24} height={24} color="rgba(15, 15, 54, 1)" />,
  'tipo-fancoil': <FancoilIcon width={24} height={24} color="rgba(15, 15, 54, 1)" />,
  condensadora: <CondenserIcon width={24} height={24} color="rgba(15, 15, 54, 1)" />,
  evaporadora: <EvaporatorIcon width={24} height={24} color="rgba(15, 15, 54, 1)" />,
  self: <SelfIcon width={24} height={24} color="rgba(15, 15, 54, 1)" />,
  splitao: <SplitaoIcon width={24} height={24} color="rgba(15, 15, 54, 1)" />,
  'split-hi-wall': <SplitHiWallIcon width={24} height={24} color="rgba(15, 15, 54, 1)" />,
  'split-cassete': <SplitCasseteIcon width={24} height={24} color="rgba(15, 15, 54, 1)" />,
  'split-piso-teto': <SplitPisoTetoIcon width={24} height={24} color="rgba(15, 15, 54, 1)" />,
  semMonit: <AtivoSemMonitIcon width={24} height={24} color="rgba(15, 15, 54, 1)" />,
};

export function updateDamStatus(message, devsList, statusOnly) {
  const dev = devsList.find((row) => row.DEV_AUT_CODE === message.dev_id);
  if (dev) {
    dev.status = message.status;
    if (!statusOnly) {
      dev.Mode = message.Mode;
      dev.OPERATION = message.State;
    }
    if (dev.status !== 'ONLINE') {
      dev.Mode = null;
      dev.OPERATION = null;
    }
    return true;
  }
  return false;
}

export function updateDutStatus(message, machineList, type) {
  let found = false;
  for (const machine of machineList) {
    let dev;
    if (type === 'dutAutTelemetry' && machine.DEV_AUT_CODE === message.dev_id) {
      dev = machine;
    } else {
      dev = machine.ENVIRONMENTS.find((env) => env.DUT_CODE === message.dev_id);
    }
    if (dev) {
      dev.status = message.status ? message.status : dev.status;
      dev.Temperature = message.Temperature ? message.Temperature : dev.Temperature;
      dev.OPERATION = message.State ? message.State : dev.State;
      dev.Temperature_1 = message.Temperature_1;
      dev.RSSI = message.RSSI;
      found = true;
    }
  }
  return found;
}
