import { ReactElement, useState } from 'react';
import { Flex } from 'reflexbox';
import { useTranslation } from 'react-i18next';
import { UnderlineBtn } from '../styles';
import { DriGroupProgItem } from './components/DriGroupProgItem';
import { DriItemList, GroupDri } from './types';

interface MultipleProgItemListProps {
  groupDriList: GroupDri[];
  onCheckDriList: (unitId: number, newList: DriItemList[]) => void;
  clientId?: number;
}

export function MultipleProgItemList({
  groupDriList,
  onCheckDriList,
  clientId,
}: Readonly<MultipleProgItemListProps>): ReactElement {
  const { t } = useTranslation();
  const [collapsedAll, setCollapsedAll] = useState(false);

  function onClickCollapsedAll(): void {
    setCollapsedAll((previousValue) => !previousValue);
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="start"
      style={{
        border: '1px solid #D2D3E2',
        borderRadius: '5px',
        padding: '10px 20px 8px',
        overflowX: 'auto',
        gap: '10px 0',
      }}
    >
      {clientId && (
        <Flex
          marginTop="10px"
        >
          <UnderlineBtn onClick={onClickCollapsedAll} style={{ cursor: 'pointer' }}>
            {t(collapsedAll ? 'expandirUnidades' : 'recolherUnidades')}
          </UnderlineBtn>
        </Flex>
      )}
      {groupDriList.map((item) => (
        <DriGroupProgItem
          collapsedAll={collapsedAll}
          data={{
            unitId: item.UNIT_ID,
            cityName: item.CITY_NAME,
            stateName: item.STATE_NAME,
            unitName: item.UNIT_NAME,
          }}
          dris={item.dris}
          onCheckDriList={(driList) => onCheckDriList(item.UNIT_ID, driList)}
          key={item.UNIT_ID}
        />
      ))}
    </Flex>
  );
}
