import { useTranslation } from 'react-i18next';
import {
  BoxIconInfo, ContainerBoxMachines, ContainerCardMachine, ContainerColorEnv, ContainerHeader, ContainerPage, ContainerSemMaquinas, ContainerSubTitles, IconColorEnv, ImageHeader, InfoMachineSubtitles, LineStyle, SearchInput, SearchInputMobile, Subtitle, TextOverlay, ThermometherArea, Title, TitleMachine,
} from './style';
import {
  SetpointCR, Automatic,
  Thermometer,
  ArrowRightIconOutlined,
} from '~/icons';
import { useStateVar } from '~/helpers/useStateVar';
import { useWebSocket } from '~/helpers/wsConnection';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { apiCall } from '~/providers';
import { getUserProfile } from '~/helpers/userProfile';
import { Link } from 'react-router-dom';
import {
  iconsMachineTypes, iconsStatesMachine, returnTempAlert, updateDamStatus, updateDutStatus,
} from './helpersIndex';
import { InputSearch, Loader } from '~/components';
import { getObjects } from '~/helpers/getObjetcs';
import { getCardColor } from '~/pages/Analysis/Units/UnitDetail/UnitDetailDUTs';

const iconsModes = {
  Automatico: <Automatic />,
  Manual: '',
};

type TMachine = {
  MACHINE_ID: number,
  MACHINE_NAME: string,
  MACHINE_TYPE: string,
  ECONOMY_ENABLED: boolean,
  SETPOINT: number,
  OPERATION: string,
  DEV_AUT_CODE: string,
  ENVIRONMENTS: {
    ENVIRONMENT_ID: number
    ENVIRONMENT_NAME: string,
    ENVIRONMENT_ROOM_TYPE_NAME: string,
  }[]
}

export const MachineListCR = (): JSX.Element => {
  const { t } = useTranslation();
  const profile = getUserProfile();
  const [state, render, setState] = useStateVar(() => {
    const state = {
      unitId: null as unknown as number,
      orderedFilter: [] as string[],
      machinesList: [] as TMachine[],
      selectedFilters: [] as string[],
      machineFiltered: [] as TMachine[],
      optionMachines: [] as { value: number, name: string }[],
      automaticMode: false,
      onTelmTimer: undefined as undefined|NodeJS.Timeout,
      hasNewTelm: false,
      searchText: '',
      CLIENT_NAME: '' as string,
      UNIT_NAME: '' as string,
      isLoading: false,
    };
    return state;
  });
  const isDesktop = window.matchMedia('(min-width: 770px)').matches;
  function getUnitId() {
    const unitIds: number[] = [];
    profile.permissions.PER_UNIT?.forEach((item) => item.units.forEach((unit) => unitIds.push(unit)));
    setState({ unitId: unitIds[0] });
  }

  async function getMachinesData(): Promise<void> {
    setState({ isLoading: true });
    try {
      const response = await apiCall('/remote-control/get-machines-list', { UNIT_ID: state.unitId });
      const optionsMachines = response.MACHINES.map((item) => ({ value: item.MACHINE_ID, name: item.MACHINE_NAME }));
      setState({ machinesList: response.MACHINES, machineFiltered: response.MACHINES, optionMachines: optionsMachines });
      setState({ CLIENT_NAME: response.CLIENT_NAME, UNIT_NAME: response.UNIT_NAME });
    } catch (err) {
      toast.error(t('erroBuscarMaquinas'));
    }
    setState({ isLoading: false });
  }

  const onSearch = (e): void => {
    setState({ searchText: e.target.value });

    if (e.target.value) {
      setState({ machineFiltered: state.machineFiltered.filter((row) => getObjects(row, e.target.value).length) });
    } else {
      setState({ machineFiltered: state.machinesList });
    }
  };

  useEffect(() => {
    getUnitId();
    getMachinesData();
  }, []);

  useWebSocket(onWsOpen, onWsMessage, beforeWsClose);
  function onWsOpen(wsConn) {
    wsConn.send({
      type: 'subscribeStatus',
      data: { unit_id: Number(state.unitId) },
    });
    wsConn.send({
      type: 'dutSubscribeRealTime',
      data: { UNIT_ID: Number(state.unitId) },
    });
  }
  function onWsMessage(payload) {
    if (payload.type === 'dutAutTelemetry' || payload.type === 'dutStatus') {
      if (updateDutStatus(payload.data, state.machineFiltered, payload.type)) {
        updateDutStatus(payload.data, state.machinesList, payload.type);
        if (state.onTelmTimer) clearTimeout(state.onTelmTimer);
        state.hasNewTelm = true;
        state.onTelmTimer = setTimeout(() => state.hasNewTelm && render(), 100);
        render();
      }
    }
    if (payload.type === 'damStatus') {
      if (updateDamStatus(payload.data, state.machineFiltered, false)) {
        if (state.onTelmTimer) clearTimeout(state.onTelmTimer);
        updateDamStatus(payload.data, state.machinesList, false);
        state.hasNewTelm = true;
        state.onTelmTimer = setTimeout(() => state.hasNewTelm && render(), 100);
        render();
      }
    }
  }
  function beforeWsClose(wsConn) {
    wsConn.send({ type: 'unsubscribeStatus' });
    wsConn.send(JSON.stringify({ type: 'dutUnsubscribeRealTime', data: {} }));
  }

  return (
    <ContainerPage isMobile={!isDesktop}>
      <HeaderUnit UNIT_NAME={state.UNIT_NAME} CLIENT_NAME={state.CLIENT_NAME} />
      { state.isLoading ? <Loader /> : (
        <>
          { isDesktop ? (
            <MachinesSearchDesktop
              state={state}
              setState={setState}
              t={t}
              onSearch={onSearch}
            />
          ) : (
            <MachinesSearchMobile
              state={state}
              onSearch={onSearch}
              t={t}
            />
          ) }
          <BoxCardsMachines MACHINES={state.machineFiltered} t={t} />
        </>
      )}
    </ContainerPage>
  );
};

function MachinesSearchDesktop({
  state, t, onSearch,
}) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 0px' }}>
      <h2 style={{ fontSize: 24, fontWeight: 600 }}>{t('maquinas')}</h2>
      <div style={{ display: 'flex', width: '30%', minWidth: '240px' }}>
        <div style={{
          display: 'flex', flexDirection: 'column', height: 'auto', width: '100%',
        }}
        >
          <SearchInput>
            <div style={{ width: '100%' }}>
              <InputSearch
                id="search"
                name="search"
                placeholder={t('buscarMaquinasAmbientes')}
                value={state.searchText}
                onChange={onSearch}
                style={{
                  margin: 0,
                }}
              />
            </div>
          </SearchInput>
        </div>
      </div>
    </div>
  );
}

function MachinesSearchMobile({
  state, t, onSearch,
}) {
  return (
    <div style={{ padding: '0px 30px' }}>
      <SearchInputMobile>
        <InputSearch
          id="search"
          name="search"
          placeholder={t('buscarMaquinasAmbientes')}
          value={state.searchText}
          onChange={onSearch}
          style={{
            margin: 0,
          }}
          isIconInFront
          colorIcon="#363BC4"
        />
      </SearchInputMobile>
    </div>
  );
}

function HeaderUnit({ UNIT_NAME, CLIENT_NAME }) {
  return (
    <ContainerHeader>
      <ImageHeader />
      <TextOverlay>
        <Title>{CLIENT_NAME}</Title>
        <Subtitle>{UNIT_NAME}</Subtitle>
      </TextOverlay>
      <LineStyle />
    </ContainerHeader>
  );
}

function BoxCardsMachines({ MACHINES, t }) {
  const isDesktop = window.matchMedia('(min-width: 770px)').matches;
  return (
    <ContainerBoxMachines isMobile={!isDesktop} haveData={MACHINES.length > 0}>
      {MACHINES.map((machine, index) => (
        <CardMachine key={machine.MACHINE_NAME} MACHINE={machine} />
      ))}
      {
        !MACHINES.length && (
          <ContainerSemMaquinas>
            <span>{t('maquinasNaoDisponiveisExibicao')}</span>
          </ContainerSemMaquinas>
        )
      }
    </ContainerBoxMachines>
  );
}

function CardMachine({ MACHINE }) {
  const isDesktop = window.matchMedia('(min-width: 770px)').matches;
  const stateInfo = iconsStatesMachine[MACHINE.OPERATION?.toUpperCase()] || null;
  const nameState = (stateInfo && typeof stateInfo.name === 'function') ? stateInfo?.name(MACHINE.DEV_AUT_CODE) : stateInfo?.name;
  return (
    <>
      { !isDesktop ? (
        <Link
          to={{
            pathname: `/controle-remoto/${MACHINE.MACHINE_ID}`,
            state: {
              MACHINE_NAME: MACHINE.MACHINE_NAME,
              MACHINE_TYPE: MACHINE.MACHINE_TYPE,
              deviceCode: MACHINE.DEV_AUT_CODE,
            },
          }}
          style={{ padding: 2, color: 'black' }}
        >
          <CardMachineInfos MACHINE={MACHINE} stateInfo={stateInfo} nameState={nameState} />
        </Link>
      ) : (
        <CardMachineInfos MACHINE={MACHINE} stateInfo={stateInfo} nameState={nameState} />
      )}
    </>
  );
}

function CardMachineInfos({
  MACHINE, stateInfo, nameState,
}) {
  const { t } = useTranslation();
  function returnIconMachine(type) {
    if (type && iconsMachineTypes[type]) {
      return iconsMachineTypes[type];
    }
    return iconsMachineTypes.semMonit;
  }
  const setpoint = MACHINE.DEV_AUT_CODE.startsWith('DAM') && !MACHINE.ECONOMY_ENABLED ? MACHINE.SETPOINT_EXCEPTION_ECO : MACHINE.SETPOINT;
  return (
    <ContainerCardMachine>
      <TitleMachine>
        <div style={{ padding: '5px 5px 5px 0px' }}>
          {returnIconMachine(MACHINE.MACHINE_TYPE)}
        </div>
        <h3>{MACHINE.MACHINE_NAME}</h3>
        <Link
          to={{
            pathname: `/controle-remoto/${MACHINE.MACHINE_ID}`,
            state: {
              MACHINE_NAME: MACHINE.MACHINE_NAME,
              MACHINE_TYPE: MACHINE.MACHINE_TYPE,
              deviceCode: MACHINE.DEV_AUT_CODE,
            },
          }}
          style={{ padding: 2, color: 'black' }}
        >
          <ArrowRightIconOutlined width={24} height={24} />
        </Link>
      </TitleMachine>
      <ContainerSubTitles>
        <InfoMachineSubtitles style={{ display: 'flex', flexDirection: 'column' }}>
          {setpoint !== null && (
            <BoxIconInfo>
              <SetpointCR />
              <span>Setpoint </span>
              <span>{`${setpoint} °C`}</span>
            </BoxIconInfo>
          )}
          {
            stateInfo && (
              <BoxIconInfo>
                <div style={{ width: '14px', height: '14px' }}>
                  {stateInfo.icon}
                </div>
                <span>{t(nameState)}</span>
              </BoxIconInfo>
            )
          }
          {
            MACHINE.ECONOMY_ENABLED && (
              <BoxIconInfo>
                {iconsModes.Automatico}
                <span>{t('modoAutomatico')}</span>
              </BoxIconInfo>
            )
          }
        </InfoMachineSubtitles>
        {
          MACHINE.ENVIRONMENTS.map((environment) => (
            <InfoMachineSubtitles
              key={environment.ENVIRONMENT_ID}
              style={{
                display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderTop: '1px solid rgba(202, 211, 224, 1)', paddingTop: 15,
              }}
            >
              <ContainerColorEnv>
                <IconColorEnv style={{ backgroundColor: getCardColor(environment.Temperature, returnTempAlert(environment.Temperature, environment.TMIN, environment.TMAX), environment.TMIN, environment.TMAX) }} />
                <h3 style={{ margin: 0 }}>{environment.ENVIRONMENT_NAME}</h3>
              </ContainerColorEnv>
              <ThermometherArea>
                <Thermometer />
                <p>{`Temp ${environment.Temperature ?? '-'}°C`}</p>
              </ThermometherArea>
            </InfoMachineSubtitles>
          ))
        }
      </ContainerSubTitles>
    </ContainerCardMachine>
  );
}
