export const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>, setUnits) => {
  const pastedData = event.clipboardData.getData('Text');
  const pastedItems = pastedData.split(/\r?\n/).map((item) => item.trim());
  setUnits(pastedItems);
  if (document.activeElement instanceof HTMLElement) {
    document.activeElement.blur();
  }
};

export const onPasteFilterOptions = (pastedItems: string[], options: {name: string, value: string}[]): string[] => {
  const parsedPastedItems = pastedItems.map((item) => item.toLowerCase());

  const response: string[] = [];
  for (const { name, value } of options) {
    if (parsedPastedItems.includes(name.toLowerCase())) {
      response.push(value);
    }
  }
  return response;
};
