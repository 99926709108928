export const SplitCasseteIcon = ({ width, height, color }: { width?: number, height?: number, color?: string }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? 17}
    height={height ?? 17}
    fill="none"
    viewBox="0 0 16 16"
  >
    <rect
      width="15"
      height="15"
      x="0.5"
      y="0.5"
      stroke={color ?? '#363BC4'}
      rx="1.5"
    />
    <path
      stroke={color ?? '#363BC4'}
      d="M.5 3.5H2A1.5 1.5 0 0 1 3.5 5v6A1.5 1.5 0 0 1 2 12.5H.5zM15.5 12.5H14a1.5 1.5 0 0 1-1.5-1.5V5A1.5 1.5 0 0 1 14 3.5h1.5zM11.5.5V2A1.5 1.5 0 0 1 10 3.5H6A1.5 1.5 0 0 1 4.5 2V.5zM4.5 15.5V14A1.5 1.5 0 0 1 6 12.5h4a1.5 1.5 0 0 1 1.5 1.5v1.5z"
    />
    <path
      stroke={color ?? '#363BC4'}
      strokeLinecap="round"
      d="M5.5 6h5M5.5 8h5M5.5 10h5"
    />
  </svg>
);
