import styled from 'styled-components';
import { colors } from 'styles/colors';

export const Text = styled.p<{ isBold? }>(
  ({ isBold }) => `
    margin-bottom: ${isBold ? 0 : '1em'};
    margin-top: ${isBold ? '1em' : 0};
    color: ${isBold ? colors.Grey300 : colors.Grey400};
    font-weight: ${isBold ? 'bold' : 'normal'};
  `,
);

const getBackgroundColor = ({ disabled, isSelected }: { disabled?: boolean; isSelected?: boolean }): string => {
  if (disabled) return colors.White;
  return isSelected ? colors.Blue300 : colors.White;
};

const getBorderColor = ({ disabled }: { disabled?: boolean }): string => (disabled ? colors.Grey200 : colors.Blue300);

const getTextColor = ({ disabled, isSelected }: { disabled?: boolean; isSelected?: boolean }): string => {
  if (disabled) return colors.Grey200;
  return isSelected ? colors.White : colors.Blue300;
};

export const SelectDay = styled.div<{ isSelected, disabled }>(
  ({ isSelected, disabled }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 48px;
    height: 32px;
    box-sizing: border-box;
    border-radius: 8px;
    margin-left: 2px;
    margin-right: 2px;
    background-color: ${getBackgroundColor({ disabled, isSelected })};
    border: 1px solid ${getBorderColor({ disabled })};
    color: ${getTextColor({ disabled, isSelected })};
  `,
);

export const ErrorMessage = styled.span`
  color: ${colors.Red};
  font-size: 0.75em;
`;

export const ProgTable = styled.div`
  display: grid;
  grid-template-columns: 43px 1fr 60px;
  color: ${colors.Grey400};
  padding-top: 12px;
`;

export const ProgTableExc = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr 60px;
  color: ${colors.Grey400};
  padding-top: 12px;
`;

export const TextProgTabDay = styled.span`
  color: ${colors.Grey300};
  font-weight: bold;
`;

export const TextProgTabMode = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;

export const ItemTitle = styled.h3`
  margin-bottom: 0;
  font-weight: bold;
  font-size: 1.25em;
  line-height: 27px;
  color: ${colors.Blue300};
`;

export const ItemWrapper = styled.div`
  width: 100%;
  border-top: 2px solid ${colors.Grey050};
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;
