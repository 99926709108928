import { ReactElement } from 'react';
import { DriProgrammingProvider } from './context/ProgrammingsContext';
import { MultipleProgContent } from './MultipleProgContent';
import { SelectDriTypes } from './types';
import { CommonPropertiesProvider } from './context/CommonPropertiesContext';

interface DriMultipleProgProps {
  selectedDevType: SelectDriTypes;
  clientId?: number;
  unitId?: number;
}

export function DriMultipleProg({
  selectedDevType,
  clientId,
  unitId,
}: Readonly<DriMultipleProgProps>): ReactElement {
  return (
    <CommonPropertiesProvider selectedDevType={selectedDevType}>
      <DriProgrammingProvider>
        <MultipleProgContent
          clientId={clientId}
          unitId={unitId}
        />
      </DriProgrammingProvider>
    </CommonPropertiesProvider>
  );
}
