import { BrowserRouter as Router } from 'react-router-dom';
import React from 'react';

import { App } from './App';

import { init as initApm } from '@elastic/apm-rum';
import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from 'helpers/auth';

declare global {
  interface Window {
    elasticApm: any;
    transaction: any;
  }
}

if (process.env.REACT_APP_APM_ELASTIC) {
  const apm = initApm({
    // Configurações do agente apm
    serviceName: 'DAP-Frontend',
    serverUrl: process.env.REACT_APP_APM_ELASTIC,
    // @ts-ignore
    secretToken: process.env.REACT_APP_APM_TOKEN,
    verifyServerCert: false,
    pageLoadTransactionName: window.location.pathname,
  });

  window.elasticApm = apm;
}

// ReactDOM.render(
//   <React.StrictMode>
//     <AuthProvider {...oidcConfig}>
//       <Router>
//         <App />
//       </Router>
//     </AuthProvider>
//   </React.StrictMode>,
//   document.getElementById('root'),
// );

export default function AppEntrypoint(): React.ReactElement {
  return (
    <AuthProvider {...oidcConfig}>
      <Router>
        <App />
      </Router>
    </AuthProvider>
  );
}
