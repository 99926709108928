export const Automatic = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    fill="none"
    viewBox="0 0 12 12"
  >
    <path
      stroke="#9C9C9C"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5.5 1 7 2.5m0 0L5.5 4M7 2.5H3.4c-.84 0-1.26 0-1.581.163a1.5 1.5 0 0 0-.656.656C1 3.639 1 4.059 1 4.9v2.85c0 .232 0 .348.013.446a1.5 1.5 0 0 0 1.291 1.291c.098.013.214.013.446.013M5 9.5h3.6c.84 0 1.26 0 1.581-.163a1.5 1.5 0 0 0 .656-.656C11 8.361 11 7.941 11 7.1V4.25c0-.232 0-.348-.013-.446a1.5 1.5 0 0 0-1.291-1.291C9.598 2.5 9.482 2.5 9.25 2.5M5 9.5 6.5 11M5 9.5 6.5 8"
    />
  </svg>
);
