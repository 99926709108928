type BlockIconProps = {
  color?: string;
  height?: number;
  width?: number;
}

export const BlockIcon = ({ height = 24, width = 24, color = '#363BC4' }: BlockIconProps): JSX.Element => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.480469 12.2783C0.480469 5.90491 5.6471 0.738281 12.0205 0.738281C18.3938 0.738281 23.5605 5.90491 23.5605 12.2783C23.5605 18.6516 18.3938 23.8183 12.0205 23.8183C5.6471 23.8183 0.480469 18.6516 0.480469 12.2783ZM12.0205 1.59069C6.11788 1.59069 1.33288 6.37569 1.33288 12.2783C1.33288 18.1809 6.11788 22.9659 12.0205 22.9659C17.9231 22.9659 22.7081 18.1809 22.7081 12.2783C22.7081 6.37569 17.9231 1.59069 12.0205 1.59069ZM4.67517 4.582C6.5844 2.75933 9.1721 1.6394 12.0205 1.6394C17.8962 1.6394 22.6594 6.40259 22.6594 12.2783C22.6594 15.1266 21.5394 17.7143 19.7168 19.6236L19.4155 19.9391L4.35966 4.88321L4.67517 4.582ZM5.58613 4.90419L19.3946 18.7126C20.8972 16.9921 21.8069 14.7418 21.8069 12.2783C21.8069 6.87336 17.4254 2.49181 12.0205 2.49181C9.55693 2.49181 7.30668 3.40157 5.58613 4.90419ZM4.32535 4.91779L19.381 19.9734L19.0394 20.2735C17.1665 21.9189 14.7095 22.9172 12.0205 22.9172C6.14478 22.9172 1.38159 18.154 1.38159 12.2783C1.38159 9.58922 2.3798 7.13225 4.02528 5.25934L4.32535 4.91779ZM4.37304 6.17096C3.03418 7.84514 2.234 9.96781 2.234 12.2783C2.234 17.6832 6.61555 22.0648 12.0205 22.0648C14.3309 22.0648 16.4536 21.2646 18.1278 19.9257L4.37304 6.17096Z" fill={color} />
  </svg>
);
