export const SetpointCR = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      fill="#9C9C9C"
      d="M6.292 4.599c.322-.065.58-.45.45-.773-.064-.321-.45-.579-.772-.45-1.737.386-2.896 1.93-2.896 3.733a3.873 3.873 0 0 0 3.862 3.861c1.995 0 3.668-1.545 3.861-3.54.064-.321-.257-.643-.58-.708-.321-.064-.643.258-.707.58-.129 1.287-1.287 2.38-2.574 2.38A2.58 2.58 0 0 1 4.36 7.11c0-1.223.773-2.253 1.931-2.51"
    />
    <path
      fill="#9C9C9C"
      d="M12.728 6.465c-.386 0-.644.257-.644.644 0 2.831-2.317 5.148-5.148 5.148-2.832 0-5.149-2.317-5.149-5.148 0-2.703 2.124-4.956 4.827-5.149.386 0 .643-.322.58-.708 0-.386-.323-.643-.709-.579C3.14.93.5 3.698.5 7.109c0 3.54 2.896 6.435 6.436 6.435s6.435-2.896 6.435-6.435a.645.645 0 0 0-.643-.644"
    />
    <path
      fill="#9C9C9C"
      d="M8.158 4.985 6.485 6.658a.62.62 0 0 0 0 .901.7.7 0 0 0 .45.193.7.7 0 0 0 .451-.193L9.06 5.886l1.095.322h.193a.58.58 0 0 0 .45-.193l2.51-2.575c.129-.128.193-.386.193-.579s-.193-.386-.386-.45l-1.159-.387L11.57.866c-.064-.193-.257-.386-.45-.386a.52.52 0 0 0-.58.193L8.03 3.247c-.192.193-.257.45-.128.644zm2.51-2.639.193.45a.58.58 0 0 0 .386.387l.451.193-1.545 1.544-.772-.257-.257-.772z"
    />
  </svg>
);
