export const InfoIconCR = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    fill="none"
    viewBox="0 0 14 15"
  >
    <circle cx="7" cy="7.25" r="7" fill="#75ABF3" />
    <path
      fill="#fff"
      d="M7.375 2.875c.503 0 .91.442.91.99 0 .546-.407.988-.91.988s-.91-.442-.91-.989.407-.989.91-.989m1.25 8.75h-2.5v-.34h.625V6.34h-.625v-.371H8v5.316h.625z"
    />
  </svg>
);
