import {
  ReactElement, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useWebSocketLazy } from 'helpers/wsConnection';
import {
  ConnectionContainer, ContainerHeader, IconButton, Subtitle, Title, WifiIconContainer,
} from '../styles';
import { BackRCIcon, WifiConnection } from 'icons';
import { useTranslation } from 'react-i18next';
import { LeavePageModal } from './LeavePageModal';

interface MachineConnectionProps {
  readonly status?: 'ONLINE' | 'OFFLINE';
}

interface HeaderMachineProps {
  readonly name: string;
  readonly type: string;
  readonly deviceCode: string;
  readonly hasEditedValues: () => boolean;
}

function MachineConnection({
  status,
}: MachineConnectionProps): ReactElement {
  const { t } = useTranslation();
  return (
    <ConnectionContainer>
      <WifiIconContainer>
        <WifiConnection disabled={status !== 'ONLINE'} />
      </WifiIconContainer>
      <p>{t(status === 'ONLINE' ? 'conectada' : 'desconectada')}</p>
    </ConnectionContainer>
  );
}

export function HeaderMachine({
  name,
  type,
  deviceCode,
  hasEditedValues,
}: HeaderMachineProps): ReactElement {
  const history = useHistory();
  const isDesktop = window.matchMedia('(min-width: 1039px)').matches;
  const isDAM = deviceCode.startsWith('DAM');
  const isDUT = deviceCode.startsWith('DUT');

  const websocketLazy = useWebSocketLazy();

  const firstRender = useRef<boolean>(true);
  const [openChangesModal, setOpenChangesModal] = useState(false);
  const [status, setStatus] = useState<string>();

  function goToListPage(): void {
    history.push('/controle-remoto');
  }

  function onClickBackButton(): void {
    if (!isDesktop && hasEditedValues()) {
      setOpenChangesModal(true);
      return;
    }

    goToListPage();
  }

  function onCloseLeaveModal(): void {
    setOpenChangesModal(false);
  }

  function onWsOpen(wsConn): void {
    if (isDAM) {
      wsConn.send({ type: 'subscribeStatus', data: { dam_id: deviceCode } });
    }
    else if (isDUT) {
      wsConn.send({ type: 'dutSubscribeRealTime', data: { DUT_ID: deviceCode } });
    }
  }

  function onWsMessage(payload): void {
    if (!payload) return;
    if (payload.data.dev_id !== deviceCode) return;
    if (!payload.data.status) return;

    setStatus(payload.data.status);
  }

  function beforeWsClose(wsConn): void {
    if (isDAM) {
      wsConn.send({ type: 'unsubscribeStatus' });
    }
    if (isDUT) {
      wsConn.send(JSON.stringify({ type: 'dutUnsubscribeRealTime', data: {} }));
    }
  }

  useEffect(() => {
    if (deviceCode && firstRender.current) {
      firstRender.current = false;
      websocketLazy.start(onWsOpen, onWsMessage, beforeWsClose);
    }
  }, [deviceCode]);

  return (
    <>
      <LeavePageModal
        open={openChangesModal}
        onClickLeave={goToListPage}
        onClose={onCloseLeaveModal}
      />
      <ContainerHeader>
        {!isDesktop && (
        <IconButton
          type="button"
          onClick={onClickBackButton}
          style={{
            height: '14px',
            margin: '0 auto 16px -8px',
          }}
        >
          <BackRCIcon />
        </IconButton>
        )}
        <Title>{name}</Title>
        <Subtitle style={{ textTransform: 'uppercase' }}>
          {type}
        </Subtitle>
        <MachineConnection status={(status ?? 'OFFLINE') as MachineConnectionProps['status']} />
      </ContainerHeader>
    </>
  );
}
