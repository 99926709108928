export const SelfIcon = ({ width, height, color }: { width?: number, height?: number, color?: string }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? 17}
    height={height ?? 17}
    fill="none"
    viewBox="0 0 16 17"
  >
    <path
      stroke={color ?? '#363BC4'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 9h2M3 7h2M7 9h2M11 9h2M7 7h2M11 7h2"
    />
    <path
      stroke={color ?? '#363BC4'}
      d="M.5 15.5a1 1 0 0 1 1-1h13a1 1 0 1 1 0 2h-13a1 1 0 0 1-1-1Z"
    />
    <rect
      width="2"
      height="4"
      x="13.5"
      y="12.5"
      fill="#fff"
      stroke={color ?? '#363BC4'}
      rx="0.5"
      transform="rotate(90 13.5 12.5)"
    />
    <rect
      width="2"
      height="4"
      x="6.5"
      y="12.5"
      fill="#fff"
      stroke={color ?? '#363BC4'}
      rx="0.5"
      transform="rotate(90 6.5 12.5)"
    />
    <path
      stroke={color ?? '#363BC4'}
      d="M1.5 1A.5.5 0 0 1 2 .5h5a.5.5 0 0 1 .5.5v1.5h-6zM8.5 1A.5.5 0 0 1 9 .5h5a.5.5 0 0 1 .5.5v1.5h-6z"
    />
    <rect
      width="15"
      height="12"
      x="0.5"
      y="2.5"
      stroke={color ?? '#363BC4'}
      rx="1.5"
    />
  </svg>
);
