export const ArrowRightIconOutlined = ({ width, height, color }: { width?: number, height?: number, color?: string }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? 17}
    height={height ?? 17}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      stroke={color ?? '#0F0F36'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 12h16m0 0-6-6m6 6-6 6"
    />
  </svg>
);
