import {
  createContext, ReactElement, ReactNode, useContext,
  useMemo,
} from 'react';
import { SelectDriTypes } from '../types';

interface CommonContextProps {
  selectedDevType: SelectDriTypes;
}

const CommonContext = createContext<CommonContextProps | undefined>(undefined);

export const useCommonPropsContext = (): CommonContextProps => {
  const context = useContext(CommonContext);

  if (!context) {
    throw new Error('useCommonPropsContext must be used within a DriProgrammingProvider');
  }

  return context;
};

export const CommonPropertiesProvider = (
  { selectedDevType, children }: { selectedDevType: SelectDriTypes, children: ReactNode },
): ReactElement => {
  const values = useMemo(() => ({
    selectedDevType,
  }), [selectedDevType]);

  return (
    <CommonContext.Provider value={values}>
      {children}
    </CommonContext.Provider>
  );
};
