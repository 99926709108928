interface CommonCardInfo {
  BEGIN_TIME: string;
  END_TIME: string;
}

interface DaySched extends CommonCardInfo {
  DAYS: string;
  ID?: number;
}

interface DayExcept extends CommonCardInfo {
  ID?: number;
}

type DayExceptObj = {[date: string]: {[year: string]: DayExcept[]}};

export interface ExceptionInfo extends CommonCardInfo {
  ID: number | undefined;
  EXCEPTION_DATE: string;
  REPEAT_YEARLY: string;
  ACTIVE: string;
}

export interface ScheduleInfo extends CommonCardInfo {
  ID: number | undefined;
  DAYS: string;
  BEGIN_TIME: string;
  END_TIME: string;
}

interface ConflictSchedule {
  foundConvergence: boolean;
  schedId: number;
  days: string;
  deleteSched: boolean;
}

interface ConflictsScheduleResponse {
  hasConflict: boolean;
  schedsConflicts: Omit<ConflictSchedule, 'foundConvergence'>[];
}

interface ConflictsExceptionsResponse {
  hasConflict: boolean;
  exceptionsIdsToDelete: number[];
}

const getNewSchedDays = (convergence: DaySched,
  newSched: DaySched): Record<string, boolean> => {
  let newDays;
  if (newSched?.ID) {
    newDays = JSON.parse(newSched.DAYS);
    const convergenceDays = JSON.parse(convergence.DAYS);
    for (const day of Object.keys(convergenceDays)) {
      if (convergenceDays[day]) newDays[day] = false;
    }
  } else if (convergence?.ID) {
    newDays = JSON.parse(convergence.DAYS);
    const convergenceDays = JSON.parse(newSched.DAYS);
    for (const day of Object.keys(convergenceDays)) {
      if (convergenceDays[day]) newDays[day] = false;
    }
  }

  return newDays;
};

function checkConvergence(
  currentScheds: DaySched[],
  newSched: ScheduleInfo,
) {
  const convergence = currentScheds.find((x) => (
    compareTimes(newSched, x)
  ));

  if (convergence) {
    const newDays = getNewSchedDays(convergence, newSched);
    let deleteSched = true;
    if (newDays !== undefined) {
      for (const day of Object.keys(newDays)) {
        if (newDays[day]) deleteSched = false;
      }
    }
    return {
      foundConvergence: true, schedId: newSched?.ID ?? convergence?.ID, days: JSON.stringify(newDays), deleteSched,
    };
  }
  return {
    foundConvergence: false, schedId: undefined, days: undefined, deleteSched: false,
  };
}

const checkConflictsByDay = (
  days: Record<string, boolean>,
  sched: ScheduleInfo,
  daysProgs: {[day: string]:DaySched[]},
  ans: ConflictsScheduleResponse,
) => {
  for (const [day, selected] of Object.entries(days)) {
    if (!selected) continue;
    const dayScheds = daysProgs[day];
    const checkConvAns = checkConvergence(dayScheds, sched);
    if (checkConvAns.foundConvergence) {
      ans.hasConflict = true;
      if (checkConvAns.schedId && !ans.schedsConflicts.find((s) => s.schedId === checkConvAns.schedId)) {
        ans.schedsConflicts.push({
          schedId: checkConvAns.schedId, deleteSched: checkConvAns.deleteSched, days: checkConvAns.days,
        });
      }
    }
    dayScheds.unshift(sched);
  }
};

export const checkSchedsCardsConflicts = (schedules:ScheduleInfo[]): ConflictsScheduleResponse => {
  const daysProgs = {
    sun: [],
    mon: [],
    tue: [],
    wed: [],
    thu: [],
    fri: [],
    sat: [],
  } as {[day: string]:DaySched[]};

  const ans: ConflictsScheduleResponse = {
    hasConflict: false,
    schedsConflicts: [],
  };

  for (const sched of schedules) {
    const days = JSON.parse(sched.DAYS) || {};
    checkConflictsByDay(days, sched, daysProgs, ans);
  }

  // Second verification
  const hasEditSchedsWithConflicts = ans.hasConflict && ans.schedsConflicts.filter((item) => item.deleteSched === false).length > 0;
  if (hasEditSchedsWithConflicts) {
    return checkNewConflicts(schedules, ans);
  }
  return ans;
};

const checkNewConflicts = (schedules: ScheduleInfo[], ans: ConflictsScheduleResponse): ConflictsScheduleResponse => {
  const notDeletedScheds = schedules.filter((item) => !ans.schedsConflicts.find((sched) => sched.schedId === item.ID)?.deleteSched);
  const updatedScheds = notDeletedScheds.map((item) => {
    const foundSched = ans.schedsConflicts.find((sched) => sched.schedId === item.ID);
    if (foundSched) {
      return {
        ...item,
        DAYS: foundSched.days,
      };
    }
    return item;
  });

  const newScheds = schedules.filter((item) => !item.ID);
  const newAns = checkSchedsCardsConflicts([...newScheds, ...updatedScheds]);

  const updatedSchedsConflicts = ans.schedsConflicts.filter((item) => !newAns.schedsConflicts.find((sched) => sched.schedId === item.schedId));
  const deleteNewConflicts = newAns.schedsConflicts.map((item) => ({ ...item, deleteSched: true }));

  if (newAns.hasConflict) {
    return {
      hasConflict: true,
      schedsConflicts: [...updatedSchedsConflicts, ...deleteNewConflicts],
    };
  }
  return ans;
};

const compareTimes = (time_1: DayExcept, time_2: DayExcept) => ((time_1.BEGIN_TIME <= time_2.BEGIN_TIME && time_2.BEGIN_TIME <= time_1.END_TIME)
  || (time_2.BEGIN_TIME <= time_1.BEGIN_TIME && time_1.BEGIN_TIME <= time_2.END_TIME)
  || (time_1.BEGIN_TIME <= time_2.BEGIN_TIME && time_2.END_TIME <= time_1.END_TIME)
  || (time_2.BEGIN_TIME <= time_1.BEGIN_TIME && time_1.END_TIME <= time_2.END_TIME));

const hasHoursConflicts = (excepts: DayExcept[]) => {
  const ans = {
    foundConvergence: false,
    exceptsIds: [] as number[],
  };
  for (let i = 0; i < excepts.length; i++) {
    for (let j = i + 1; j < excepts.length; j++) {
      if (compareTimes(excepts[i], excepts[j])) {
        ans.foundConvergence = true;
        if (excepts[i]?.ID) {
          ans.exceptsIds.push(excepts[i].ID as number);
        }
        if (excepts[j]?.ID) {
          ans.exceptsIds.push(excepts[j].ID as number);
        }
      }
    }
  }
  return ans;
};

const handleDayExceptsInsertion = (dayExcepts: DayExceptObj, dateDM: string, except: ExceptionInfo) => {
  if (except.REPEAT_YEARLY === '1') {
    if (!dayExcepts[dateDM]?.YEARLY) {
      dayExcepts[dateDM].YEARLY = [{ BEGIN_TIME: except.BEGIN_TIME, END_TIME: except.END_TIME, ID: except.ID }];
    } else {
      dayExcepts[dateDM].YEARLY.push({ BEGIN_TIME: except.BEGIN_TIME, END_TIME: except.END_TIME, ID: except.ID });
    }
  } else {
    const dateY = except.EXCEPTION_DATE.slice(6, 10);
    if (!dayExcepts[dateDM]?.[dateY]) {
      dayExcepts[dateDM][dateY] = [{ BEGIN_TIME: except.BEGIN_TIME, END_TIME: except.END_TIME, ID: except.ID }];
    } else {
      dayExcepts[dateDM][dateY].push({ BEGIN_TIME: except.BEGIN_TIME, END_TIME: except.END_TIME, ID: except.ID });
    }
  }
};

const getDayExcepts = (exceptions: ExceptionInfo[], persisted?: ExceptionInfo[]) => {
  if (persisted) {
    exceptions = exceptions.concat(persisted);
  }

  const dayExcepts = {} as {[date: string]: {[year: string]: DayExcept[]}};
  for (const except of exceptions) {
    if (except.ACTIVE === '0') continue;
    const dateDM = except.EXCEPTION_DATE.slice(0, 5);
    if (!dayExcepts[dateDM]) dayExcepts[dateDM] = {};

    handleDayExceptsInsertion(dayExcepts, dateDM, except);
  }

  return dayExcepts;
};

const checkNotYearlyExcept = (dayExcepts: DayExceptObj, date: string, yearlyExcepts: DayExcept[], ans: ConflictsExceptionsResponse) => {
  for (const year of Object.keys(dayExcepts[date])) {
    if (year === 'YEARLY') continue;
    const excepts = dayExcepts[date][year].concat(yearlyExcepts);
    const checkExceptsConvergence = hasHoursConflicts(excepts);
    if (checkExceptsConvergence.foundConvergence) {
      ans.hasConflict = true;
      if (checkExceptsConvergence.exceptsIds.length) {
        ans.exceptionsIdsToDelete = ans.exceptionsIdsToDelete.concat(checkExceptsConvergence.exceptsIds);
      }
    }
  }
};

export const checkExceptsCardsConflicts = (persisted?: ExceptionInfo[]): ConflictsExceptionsResponse => {
  const ans: ConflictsExceptionsResponse = {
    hasConflict: false,
    exceptionsIdsToDelete: [],
  };

  const dayExcepts = getDayExcepts(persisted ?? []);

  for (const date of Object.keys(dayExcepts)) {
    const yearlyExcepts = dayExcepts[date]?.YEARLY || [];
    const checkExceptsConvergence = hasHoursConflicts(yearlyExcepts);
    if (checkExceptsConvergence.foundConvergence) {
      ans.hasConflict = true;
      if (checkExceptsConvergence.exceptsIds.length) {
        ans.exceptionsIdsToDelete = ans.exceptionsIdsToDelete.concat(checkExceptsConvergence.exceptsIds);
      }
    }
    checkNotYearlyExcept(dayExcepts, date, yearlyExcepts, ans);
  }
  ans.exceptionsIdsToDelete = ans.exceptionsIdsToDelete.filter((e) => e !== undefined);
  return ans;
};
