import {
  ReactElement, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { GroupButton, TooltipSpan } from '../styles';
import { Checkbox } from '~/components';
import { Flex } from 'reflexbox';
import { cutText } from '~/helpers/formatText';
import ReactTooltip from 'react-tooltip';
import { StyledLink } from '../../styles';
import { ArrowDownIconV2, ArrowUpIconV2 } from '~/icons';
import { DriProgItem } from './DriProgItem';
import { DriItemList } from '../types';

interface MultipleGroupProgItemProps {
  data: {
    unitId: number | null;
    unitName?: string | null;
    stateName?: string | null;
    cityName?: string | null;
  }
  dris: DriItemList[];
  collapsedAll: boolean;
  onCheckDriList: (newList: DriItemList[]) => void;
}

export function DriGroupProgItem({
  collapsedAll,
  data,
  dris,
  onCheckDriList,
}: Readonly<MultipleGroupProgItemProps>): ReactElement {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);
  const [checkedUnit, setCheckedUnit] = useState(false);

  const isAllSelected = useMemo(() => dris.every((item) => item.checked), [dris]);

  function onCheckUnit(): void {
    const newArrayDris = dris.map((item) => ({ ...item, checked: !checkedUnit }));
    onCheckDriList(newArrayDris);
  }

  function onClickGroupButton(): void {
    setCollapsed((previousValue) => !previousValue);
  }

  function onCheckItem(id: string): void {
    const newArray = dris.map((item) => (
      item.DRI_ID === id ? { ...item, checked: !item.checked } : item
    ));
    onCheckDriList(newArray);
  }

  useEffect(() => {
    if (isAllSelected && !checkedUnit) {
      setCheckedUnit(true);
    } else if (checkedUnit) {
      setCheckedUnit(false);
    }
  }, [isAllSelected]);

  return (
    <>
      <GroupButton
        type="button"
        onClick={onClickGroupButton}
        style={{
          minWidth: '978px',
          width: '100%',
        }}
      >
        <Checkbox
          size={20}
          checked={isAllSelected}
          onClick={(e) => {
            e.stopPropagation();
            onCheckUnit();
          }}
          style={{
            gridColumn: 'span 1 / span 1',
          }}
        />
        <Flex
          flexDirection="column"
          alignItems="start"
          style={{
            gridColumn: 'span 1 / span 1',
          }}
        >
          <span style={{ fontWeight: 'bold', fontSize: '12px' }}>
            {t('estado')}
          </span>
          <span>
            {data.stateName ?? '-'}
          </span>
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="start"
          style={{
            gridColumn: 'span 1 / span 1',
          }}
        >
          <span style={{ fontWeight: 'bold', fontSize: '12px' }}>
            {t('cidade')}
          </span>
          <span data-tip data-for={`city-${data.unitId}`}>
            {cutText(data.cityName ?? '-', 15)}
          </span>
          {data.cityName && data.cityName.length >= 15 && (
          <ReactTooltip
            id={`city-${data.unitId}`}
            place="top"
            effect="solid"
            delayHide={100}
            offset={{ top: 0, left: 10 }}
            textColor="#000000"
            border
            backgroundColor="rgba(256, 256, 256, 1)"
          >
            <Flex
              flexWrap="nowrap"
              flexDirection="column"
              alignItems="left"
            >
              <TooltipSpan>
                {data.cityName}
              </TooltipSpan>
            </Flex>
          </ReactTooltip>
          )}
        </Flex>
        <Flex
          flexDirection="column"
          alignItems="start"
          style={{
            gridColumn: 'span 1 / span 1',
          }}
        >
          <span style={{ fontWeight: 'bold', fontSize: '12px' }}>
            {t('unidade')}
          </span>
          <div>
            {data.unitName ? (
              <StyledLink
                data-tip
                data-for={data.unitId}
                to={`/analise/unidades/${data.unitId}`}
                style={{
                  marginLeft: '0px',
                  textDecoration: 'underline',
                }}
                color="#8b8b8b"
              >
                {cutText(data.unitName, 30)}
              </StyledLink>
            ) : ('-')}
          </div>
          {data.unitName && data.unitName.length >= 30 && (
            <ReactTooltip
              id={data.unitId?.toString() ?? 'no-unitId'}
              place="top"
              effect="solid"
              delayHide={100}
              offset={{ top: 0, left: 10 }}
              textColor="#000000"
              border
              backgroundColor="rgba(256, 256, 256, 1)"
            >
              <Flex flexWrap="nowrap" flexDirection="column" alignItems="left">
                <TooltipSpan>
                  {data.unitName}
                </TooltipSpan>
              </Flex>
            </ReactTooltip>
          )}
        </Flex>
        <Flex style={{
          gridColumn: 'span 1 / span 1',
          gridColumnStart: '6',
        }}
        >
          {(collapsed || collapsedAll) ? (
            <ArrowDownIconV2
              color="#8b8b8b"
              width="10"
              heigth="10"
            />
          ) : (
            <ArrowUpIconV2
              color="#8b8b8b"
              width="10"
              heigth="10"
            />
          )}
        </Flex>
      </GroupButton>
      {!(collapsed || collapsedAll) && (
        <DriProgItem
          data={dris}
          onCheckItem={onCheckItem}
        />
      )}
    </>
  );
}
