import { useAuth } from 'react-oidc-context';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import posthog from 'posthog-js';

export function Logout() {
  const { isAuthenticated } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const toPage = queryParams.get('topage');

  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.clear();
      posthog.reset();
      dispatch({ type: 'RESET_DATA' });
      const queryString = toPage ? `?topage=${toPage}` : '';
      history.push(`/login${queryString}`);
      return;
    }

    history.push('/analise/unidades');
  }, [dispatch, history, isAuthenticated, toPage]);

  return null;
}
