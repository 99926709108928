import { t } from 'i18next';
import { DriException, DriSchedule } from 'pages/Analysis/SchedulesModals/DRI_ScheduleModal';
import { apiCall, ApiParams } from 'providers';
import { toast } from 'react-toastify';
import { ScheduleInfo } from '~/pages/Analysis/SchedulesModals/DRI_Shedule';

export type SelectedDays = {
  mon?: boolean;
  tue?: boolean;
  wed?: boolean;
  thu?: boolean;
  fri?: boolean;
  sat?: boolean;
  sun?: boolean;
}

type Interval = {
  startTime: string;
  endTime: string
}

export function checkExceptionDate(
  exceptionToCompare: DriException,
  exception: Pick<DriException, 'EXCEPTION_DATE' | 'EXCEPTION_REPEAT_YEARLY'>,
): boolean {
  if (exceptionToCompare.EXCEPTION_REPEAT_YEARLY === '1' || exception.EXCEPTION_REPEAT_YEARLY === '1') {
    return exceptionToCompare.EXCEPTION_DATE.slice(0, 5) === exception.EXCEPTION_DATE.slice(0, 5);
  }
  return exceptionToCompare.EXCEPTION_DATE === exception.EXCEPTION_DATE;
}

export function checkWeekDayCommon(days1: SelectedDays, days2: SelectedDays): boolean {
  let result = days1.mon && days2.mon;
  result = result || (days1.tue && days2.tue);
  result = result || (days1.wed && days2.wed);
  result = result || (days1.thu && days2.thu);
  result = result || (days1.fri && days2.fri);
  result = result || (days1.sat && days2.sat);
  result = result || (days1.sun && days2.sun);

  return !!result;
}

export function checkTimeConflict(intervalToCompare: Interval, interval: Interval): boolean {
  return (intervalToCompare.startTime <= interval.startTime && interval.startTime <= intervalToCompare.endTime)
    || (interval.startTime <= intervalToCompare.startTime && intervalToCompare.startTime <= interval.endTime)
    || (intervalToCompare.startTime <= interval.startTime && interval.endTime <= intervalToCompare.endTime)
    || (interval.startTime <= intervalToCompare.startTime && intervalToCompare.endTime <= interval.endTime);
}

export async function callDriSchedules(devId: string): Promise<{
  schedules: DriSchedule[],
  exceptions: DriException[],
}> {
  try {
    const { list: schedulesList } = await apiCall('/dri/get-dri-scheds', { DRI_ID: devId });
    const schedules: DriSchedule[] = [];
    const exceptions: DriException[] = [];

    for (const schedule of schedulesList) {
      if (schedule.EXCEPTION_DATE) {
        exceptions.push(schedule);
      } else {
        schedules.push(schedule);
      }
    }

    return {
      schedules,
      exceptions,
    };
  } catch (error) {
    console.error(error);
    toast.error(t('naoFoiPossivelBuscarProgramacoesExcecoesDRI'));

    return {
      exceptions: [],
      schedules: [],
    };
  }
}

export async function deleteProgramming(refetch: () => void, sched?: ApiParams['/dri/delete-dri-sched']): Promise<void> {
  if (!sched) { return; }
  try {
    const { SCHED_ID, DRI_ID } = sched;
    await apiCall('/dri/delete-dri-sched', { SCHED_ID, DRI_ID });
    refetch();
    toast.success(t('sucessoRemoverProgramacao'));
  } catch (err) {
    console.log(err);
    toast.error(t('erro'));
  }
}

export async function insertProgramming(refetch: () => void, sched?: ApiParams['/dri/add-dri-sched']): Promise<void> {
  if (!sched) { return; }

  try {
    await apiCall('/dri/add-dri-sched', sched);
    refetch();

    toast.success(t('sucessoAdicionarProgramacao'));
  } catch (err) {
    console.log(err);
    toast.error(t('erro'));
  }
}

export async function updateProgramming(refetch: () => void, sched?: ApiParams['/dri/update-dri-sched']): Promise<void> {
  if (!sched) { return; }

  try {
    await apiCall('/dri/update-dri-sched', sched);
    refetch();

    toast.success(t('sucessoAdicionarProgramacao'));
  } catch (err) {
    console.log(err);
    toast.error(t('erro'));
  }
}

export function generateDaysObject(days?: Record<string, boolean>): SelectedDays {
  return {
    mon: days ? days.mon : false,
    tue: days ? days.tue : false,
    wed: days ? days.wed : false,
    thu: days ? days.thu : false,
    fri: days ? days.fri : false,
    sat: days ? days.sat : false,
    sun: days ? days.sun : false,
  };
}

export function getSchedTimesValues(item?: ScheduleInfo): {
  start_time: string
  start_time_error: string
  end_time: string
  end_time_error: string
} {
  return {
    start_time: item ? item.BEGIN_TIME : '',
    start_time_error: '',
    end_time: item ? item.END_TIME : '',
    end_time_error: '',
  };
}
