import { Route, Redirect } from 'react-router-dom';
import { getUserProfile } from '~/helpers/userProfile';

export const Route404 = ({ ...params }): JSX.Element => {
  const isLogged = !!localStorage.getItem('@diel:token');
  const profile = getUserProfile();
  const isRemoteControl = profile.permissions.isPersonaII || false;

  function redirect() {
    if (isLogged) {
      if (isRemoteControl) {
        return <Redirect to="/controle-remoto" />;
      }
      return <Redirect to="/analise/unidades" />;
    }
    return <Redirect to="/login" />;
  }
  return (
    <Route
      {...params}
      render={redirect}
    />
  );
};
