import { ReactElement } from 'react';
import { Button, ModalWindow } from 'components';
import { CloseIcon, SaveIcon } from 'icons';
import { colors } from '~/styles/remoteControlColors';
import { FooterContainerModal, IconButton } from '../styles';
import { useTranslation } from 'react-i18next';

interface SaveChangesModalProperties {
  open: boolean;
  onClose: () => void;
  onClickSave: () => void;
  disabled?: boolean;
}

export function SaveChangesModal({
  open,
  onClose,
  onClickSave,
  disabled,
}: SaveChangesModalProperties): ReactElement | null {
  const isDesktop = window.matchMedia('(min-width: 1039px)').matches;
  const { t } = useTranslation();

  return open ? (
    <ModalWindow
      style={{
        padding: '0',
        overflowX: 'hidden',
        width: '512px',
      }}
      onClickOutside={onClose}
      variant={!isDesktop ? 'mobile-rc' : undefined}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '40px',
        position: 'relative',
        padding: '24px',
      }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              <SaveIcon color={colors.Blue02} width="20" />
              <h1 style={{
                color: colors.Blue02,
                fontWeight: '700',
                fontSize: '24px',
                lineHeight: '24px',
                margin: 0,
              }}
              >
                {t('salvarAlteracoes')}
              </h1>
            </div>
            <IconButton
              type="button"
              onClick={onClose}
            >
              <CloseIcon color={colors.Blue02} size="12" />
            </IconButton>
          </div>
          <p style={{
            fontSize: '16px',
            fontWeight: '500',
            color: colors.Grey,
          }}
          >
            {t('desejaAplicarAlteracoes')}
          </p>
        </div>
        <FooterContainerModal>
          <Button
            type="button"
            variant="rc-green"
            onClick={onClickSave}
            disabled={disabled}
          >
            {t('botaoConfirmar')}
          </Button>
          <Button
            type="button"
            variant="rc-grey"
            onClick={onClose}
            disabled={disabled}
          >
            {t('cancelar')}
          </Button>
        </FooterContainerModal>
      </div>
    </ModalWindow>
  ) : null;
}
