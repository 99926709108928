export const Thermometer = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="7"
    height="14"
    fill="none"
    viewBox="0 0 7 14"
  >
    <path
      fill="#9C9C9C"
      d="M4.074 8.938V7.591a.6.6 0 0 0-.17-.418.58.58 0 0 0-.823 0 .6.6 0 0 0-.17.418v1.347a1.2 1.2 0 0 0-.425.43 1.191 1.191 0 0 0 .183 1.423 1.155 1.155 0 0 0 1.646 0 1.19 1.19 0 0 0 .183-1.423 1.2 1.2 0 0 0-.424-.43m2.037-1.347V3.159a2.68 2.68 0 0 0-.767-1.88A2.6 2.6 0 0 0 3.492.5c-.694 0-1.36.28-1.851.779a2.68 2.68 0 0 0-.767 1.88v4.432A3.57 3.57 0 0 0 .072 9.22a3.6 3.6 0 0 0 .1 1.817c.19.587.525 1.114.976 1.528s1 .702 1.594.835q.37.085.75.1a3.45 3.45 0 0 0 1.905-.564 3.53 3.53 0 0 0 1.295-1.527 3.6 3.6 0 0 0-.581-3.818m-1.164 4.178a2.31 2.31 0 0 1-3.294-.201 2.39 2.39 0 0 1 .198-3.345.6.6 0 0 0 .175-.42V3.16c0-.392.153-.767.426-1.044a1.44 1.44 0 0 1 1.029-.433c.385 0 .755.155 1.028.433.273.277.426.652.426 1.044v4.692a.6.6 0 0 0 .175.42c.238.233.424.515.546.827a2.4 2.4 0 0 1-.09 1.945c-.15.3-.36.563-.619.773z"
    />
  </svg>
);
