export const AtivoSemMonitIcon = ({ width, height, color }: { width?: number, height?: number, color?: string }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? 17}
    height={height ?? 17}
    fill="none"
    viewBox="0 0 17 17"
  >
    <path
      stroke={color ?? '#363BC4'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M14.846 1H2.154A1.154 1.154 0 0 0 1 2.154v5.77a1.154 1.154 0 0 0 1.154 1.153h12.692A1.153 1.153 0 0 0 16 7.923v-5.77A1.154 1.154 0 0 0 14.846 1"
    />
    <path
      stroke={color ?? '#363BC4'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M13.115 9.077V6.769a1.154 1.154 0 0 0-1.154-1.154H5.038A1.154 1.154 0 0 0 3.885 6.77v2.308m-.577 5.77h.115a1.615 1.615 0 0 0 1.615-1.616v-1.27m8.654 2.885h-.115a1.615 1.615 0 0 1-1.616-1.615v-1.27M8.5 16v-4.039"
    />
  </svg>
);
