type VentIconProps = {
  color?: string;
  height?: number;
  width?: number;
}

export const VentIcon = ({ height = 22, width = 20, color = '#9C9C9C' }: VentIconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 22 20"
  >
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M11 14.14a2.996 2.996 0 0 0 2.993-3c0-1.657-1.34-3-2.993-3a2.996 2.996 0 0 0-2.992 3c0 1.657 1.34 3 2.992 3"
    />
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M13.162 8.64q1.08-2.307 1.3-4.514c.219-1.63-.76-2.986-3.462-2.986-2.7 0-3.68 1.357-3.461 2.986q.22 2.206 1.3 4.514M12.166 14.892q1.453 2.092 3.249 3.386c1.297 1.006 2.96.833 4.31-1.512s.668-3.874-.848-4.498q-2.015-.913-4.55-1.128M7.673 11.14q-2.533.215-4.548 1.128c-1.518.623-2.2 2.153-.85 4.498s3.012 2.517 4.31 1.512q1.796-1.294 3.25-3.386"
    />
  </svg>
);
