import posthog from 'posthog-js';
import { Profile } from './userProfile';

// interface UserGuiding {
//   identifyuser: (
//     userId: string,
//     userInfo: {
//       email: string;
//       name: string|null;
//       maintainer: boolean;
//       admin: boolean;
//       instalador: boolean;
//       validator: boolean;
//     }
//   ) => void;
// }
// declare global {
//   interface Window {
//     userGuiding: UserGuiding;
//   }
// }

export const identifyUser = (user: Profile) => {
  posthog.identify(user.user, {
    email: user.user,
    name: user.name,
    maintainer: user.permissions.isUserManut || false,
    admin: user.permissions.isAdminSistema || false,
    instalador: user.permissions.isInstaller || false,
    validator: user.permissions.isParceiroValidador || false,
  });
  // const userWindow = window as unknown as UserGuiding;
  // if (window.userGuiding?.identifyuser) {
  //   window.userGuiding.identifyuser(user.user, {
  //     email: user.user,
  //     name: user.name,
  //     maintainer: user.permissions.isUserManut || false,
  //     admin: user.permissions.isAdminSistema || false,
  //     instalador: user.permissions.isInstaller || false,
  //     validator: user.permissions.isParceiroValidador || false,
  //   });
  // }
};
