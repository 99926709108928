import { ReactElement, useEffect, useState } from 'react';
import { Box, Flex } from 'reflexbox';
import {
  IconWrapperSearch, Label, SearchInput, UnderlineBtn,
} from '../styles';
import { useTranslation } from 'react-i18next';
import { SearchIcon } from '~/icons';
import {
  connectionsOptions, delimiters, filterOptions, flexGrowFilterFields, programmingOptions,
} from './constants';
import { WithContext as ReactTags } from 'react-tag-input';
import SelectSearch, { SelectSearchProps } from 'react-select-search';
import { FilterFormData, Option, SelectedOptions } from './types';
import { Button } from '~/components';
import { useDebounce } from '~/hooks';

type SelectedOptionKey = keyof SelectedOptions;

interface MultipleProgFilterProps {
  loading: boolean;
  options: {
    states: Option[];
    cities: Option[];
    units: Option[];
    roomTypes: Option[];
  };
  onSubmit: (data: FilterFormData) => void;
  clientId?: number;
  unitId?: number;
}

function CleanButton({ onClick }: Readonly<{ onClick: () => void }>): ReactElement {
  const { t } = useTranslation();

  return (
    <UnderlineBtn
      onClick={onClick}
    >
      {t('limparFiltro')}
    </UnderlineBtn>
  );
}

export function MultipleProgFilter({
  loading,
  options,
  onSubmit,
  clientId,
  unitId,
}: Readonly<MultipleProgFilterProps>): ReactElement {
  const { t } = useTranslation();

  const [searchState, setSearchState] = useState<{id: string, text: string}[]>([]);
  const searchStateDebounced = useDebounce(searchState, 500);

  const [selectedOptions, setSelectedOptions] = useState<SelectedOptions>({
    states: [],
    cities: [],
    units: [],
    connections: [],
    programming: [],
    roomTypes: [],
  });

  function handleSearchDelete(i: number): void {
    setSearchState((previousState) => previousState.filter((_, index) => index !== i));
  }

  function handleSearchAddition(tag: {id: string, text: string}): void {
    setSearchState((previousState) => [...previousState, tag]);
  }

  function clearSelectedFilter(filter: SelectedOptionKey): void {
    setSelectedOptions((previousState) => ({
      ...previousState,
      [filter]: [],
    }));
  }

  function onFilterSelected(filter: SelectedOptionKey, values: string[]): void {
    setSelectedOptions((previousState) => ({
      ...previousState,
      [filter]: values,
    }));
  }

  function onClickFilter(): void {
    onSubmit({ searchState: searchState.map(({ text }) => text), ...selectedOptions });
  }

  function defaultSelectSearchProps(multiple?: boolean): Partial<SelectSearchProps> {
    return {
      printOptions: 'on-focus',
      search: true,
      multiple: multiple ?? true,
      filterOptions,
      closeOnSelect: false,
    };
  }

  useEffect(() => {
    if (unitId) {
      onSubmit({ searchState: searchStateDebounced.map(({ text }) => text), ...selectedOptions });
    }
  }, [searchStateDebounced]);

  return (
    <Flex
      flexWrap="nowrap"
      flexDirection="column"
    >
      <Flex
        alignItems={['center', 'center', 'center', 'center', 'flex-start']}
        justifyContent="space-between"
        flexDirection={['column', 'column', 'column', 'column', 'row']}
        flexWrap="wrap"
        mt={15}
        mb={15}
        style={{ gap: '8px 0' }}
      >
        <Flex
          flexWrap="wrap"
          flexDirection="row"
          alignItems="left"
          style={{ gap: '8px 15px' }}
          width="90%"
        >
          <Box width="300px" flexGrow={flexGrowFilterFields}>
            <SearchInput>
              <div style={{ width: '100%' }}>
                <Label>{t('pesquisar')}</Label>
                <ReactTags
                  tags={searchState}
                  handleDelete={handleSearchDelete}
                  handleAddition={handleSearchAddition}
                  delimiters={delimiters}
                  allowDragDrop={false}
                  allowDeleteFromEmptyInput={false}
                  inputFieldPosition="top"
                  minQueryLength={2}
                  placeholder=""
                />
              </div>
              <IconWrapperSearch>
                <SearchIcon />
              </IconWrapperSearch>
            </SearchInput>
          </Box>
          {clientId && (
          <>
            <Box width="300px" flexGrow={flexGrowFilterFields}>
              <SearchInput>
                <div style={{ width: '100%', paddingTop: 3 }}>
                  <Label>{t('estado')}</Label>
                  <SelectSearch
                    options={options.states}
                    value={selectedOptions.states}
                    onChange={(value) => {
                      onFilterSelected('states', value as unknown as string[]);
                    }}
                    placeholder={t('selecioneOsEstados')}
                    disabled={loading}
                    {...defaultSelectSearchProps()}
                  />
                </div>
              </SearchInput>
              <CleanButton
                onClick={() => clearSelectedFilter('states')}
              />
            </Box>
            <Box width="300px" flexGrow={flexGrowFilterFields}>
              <SearchInput>
                <div style={{ width: '100%', paddingTop: 3 }}>
                  <Label>{t('cidade')}</Label>
                  <SelectSearch
                    options={options.cities}
                    value={selectedOptions.cities}
                    onChange={(value) => {
                      onFilterSelected('cities', value as unknown as string[]);
                    }}
                    placeholder={t('selecioneAsCidades')}
                    disabled={loading}
                    {...defaultSelectSearchProps()}
                  />
                </div>
              </SearchInput>
              <CleanButton
                onClick={() => clearSelectedFilter('cities')}
              />
            </Box>
            <Box width="300px" flexGrow={flexGrowFilterFields}>
              <SearchInput>
                <div style={{ width: '100%', paddingTop: 3 }}>
                  <Label>{t('unidades')}</Label>
                  <SelectSearch
                    options={options.units}
                    value={selectedOptions.units}
                    onChange={(value) => {
                      onFilterSelected('units', value as unknown as string[]);
                    }}
                    placeholder={t('selecioneAsUnidades')}
                    disabled={loading}
                    {...defaultSelectSearchProps()}
                  />
                </div>
              </SearchInput>
              <CleanButton
                onClick={() => clearSelectedFilter('units')}
              />
            </Box>
            <Box width="300px" flexGrow={flexGrowFilterFields}>
              <SearchInput>
                <div style={{ width: '100%', paddingTop: 3 }}>
                  <Label>{t('programacao')}</Label>
                  <SelectSearch
                    options={programmingOptions}
                    value={selectedOptions.programming}
                    onChange={(value) => {
                      onFilterSelected('programming', value as unknown as string[]);
                    }}
                    placeholder={t('selecioneTipo')}
                    disabled={loading}
                    {...defaultSelectSearchProps(false)}
                  />
                </div>
              </SearchInput>
              <CleanButton
                onClick={() => clearSelectedFilter('programming')}
              />
            </Box>
            <Box width="300px" flexGrow={flexGrowFilterFields}>
              <SearchInput>
                <div style={{ width: '100%', paddingTop: 3 }}>
                  <Label>{t('tipoAmbiente')}</Label>
                  <SelectSearch
                    options={options.roomTypes}
                    value={selectedOptions.roomTypes}
                    onChange={(value) => {
                      onFilterSelected('roomTypes', value as unknown as string[]);
                    }}
                    placeholder={t('selecioneTipo')}
                    disabled={loading}
                    {...defaultSelectSearchProps()}
                  />
                </div>
              </SearchInput>
              <CleanButton
                onClick={() => clearSelectedFilter('roomTypes')}
              />
            </Box>
            <Box width="300px" flexGrow={flexGrowFilterFields}>
              <SearchInput>
                <div style={{ width: '100%', paddingTop: 3 }}>
                  <Label>{t('conexao')}</Label>
                  <SelectSearch
                    options={connectionsOptions}
                    value={selectedOptions.connections}
                    onChange={(value) => {
                      onFilterSelected('connections', value as unknown as string[]);
                    }}
                    placeholder={t('selecioneAConexao')}
                    disabled={loading}
                    {...defaultSelectSearchProps()}
                  />
                </div>
              </SearchInput>
            </Box>
          </>
          )}
        </Flex>
        {!unitId && (
        <Button
          style={{
            width: '110px',
            minWidth: '100px',
          }}
          type="button"
          variant={loading ? 'disabled' : 'primary'}
          onClick={onClickFilter}
        >
          {t('filtrar')}
        </Button>
        )}
      </Flex>
    </Flex>
  );
}
