type ReturnIconProps = {
  color?: string;
  size?: string;
}

export const ReturnIcon = ({ color, size }: ReturnIconProps): JSX.Element => (
  <svg width={size ?? '20'} height={size ?? '15'} viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 5H14.5C16.9853 5 19 7.01472 19 9.5C19 11.9853 16.9853 14 14.5 14H10M1 5L5 1M1 5L5 9" stroke={color ?? 'black'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
