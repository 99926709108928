import { ReactElement, useEffect, useState } from 'react';
import { Button, Checkbox, ModalWindow } from 'components';
import { Box, Flex } from 'reflexbox';
import { SmallTrashIcon } from '~/icons/Trash';
import { useTranslation } from 'react-i18next';
import { UnderlineBtn } from '../../styles';
import { ClearState } from '../types';

interface ConfirmDeleteProgExceptionProps {
  onSubmit: (data: ClearState) => void;
  onClose: () => void;
  open: boolean;
  loading?: boolean;
}

export function ConfirmDeleteProgException({
  open,
  onClose,
  onSubmit,
  loading = false,
}: ConfirmDeleteProgExceptionProps): ReactElement | null {
  const { t } = useTranslation();
  const [clearState, setClearState] = useState<ClearState>({
    exceptions: false,
    schedules: false,
  });

  function onClickCheckbox(key: keyof ClearState): void {
    setClearState((previousValues) => ({
      ...previousValues,
      [key]: !previousValues[key],
    }));
  }

  useEffect(() => {
    if (!open) {
      setClearState({
        exceptions: false,
        schedules: false,
      });
    }
  }, [open]);

  return open ? (
    <ModalWindow
      style={{ padding: '0', overflowX: 'hidden', width: '455px' }}
      borderTop
      onClickOutside={onClose}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="80px"
      >
        <Box width="100%">
          <Flex>
            <SmallTrashIcon color="#DC0E01" />
            <h4 style={{ fontWeight: 'bold', marginLeft: '10px' }}>
              {t('limparExistentes')}
            </h4>
          </Flex>
          <p style={{ color: '#8b8b8b', hyphens: 'auto' }}>
            {t('confirmacaoLimpezaPt1')}
            &nbsp;
            <span style={{ fontWeight: 'bold' }}>
              {t('confirmacaoLimpezaPt2')}
            </span>
            &nbsp;
            {t('confirmacaoLimpezaPt3')}
          </p>
          <Flex alignItems="center" justifyContent="space-between">
            <Checkbox
              size={20}
              checked={clearState.schedules}
              label={t('programacoes')}
              onClick={() => onClickCheckbox('schedules')}
              style={{ color: '#8b8b8b' }}
            />
            <Checkbox
              size={20}
              checked={clearState.exceptions}
              label={t('excecoes')}
              onClick={() => onClickCheckbox('exceptions')}
              style={{ color: '#8b8b8b' }}
            />
          </Flex>
          <Flex marginTop="40px" alignItems="center" justifyContent="space-between">
            <Button
              style={{ width: '200px' }}
              variant={loading ? 'disabled' : 'secondary'}
              onClick={() => onSubmit(clearState)}
              disabled={loading}
            >
              {t('limpar')}
            </Button>
            <UnderlineBtn onClick={onClose} disabled={loading}>
              {t('cancelar')}
            </UnderlineBtn>
          </Flex>
        </Box>
      </Flex>
    </ModalWindow>
  ) : null;
}
