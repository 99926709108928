import { apiCall } from 'providers';
import {
  createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { toast } from 'react-toastify';
import { DriSchedListResponse } from '../types';

export interface DriProgramming {
  [DRI_ID: string]: DriSchedListResponse;
}

interface SchedsContextProps {
  scheds: DriProgramming;
  fetchScheds: (driIds: string[]) => Promise<void>;
  clearSavedProgramming: (driIds: string[]) => void;
  refetchScheds: (driIds: string[]) => Promise<void>;
}

const DriProgrammingContext = createContext<SchedsContextProps | undefined>(undefined);

export const useDriProgrammingContext = (): SchedsContextProps => {
  const context = useContext(DriProgrammingContext);

  if (!context) {
    throw new Error('useDriProgrammingContext must be used within a DriProgrammingProvider');
  }

  return context;
};

export const DriProgrammingProvider = (
  { children }: { children: ReactNode },
): ReactElement => {
  const [driProgramming, setDriProgramming] = useState<DriProgramming>({});
  const timeouts = useRef<Record<string, NodeJS.Timeout>>({});
  const retryInterval = 5 * 60 * 1000;

  const fetchScheds = useCallback(async (driIds: string[]) => {
    try {
      const promises = driIds.map((driId) => apiCall('/dri/get-dri-scheds', { DRI_ID: driId }).then((value) => ({
        driId,
        response: value,
      })));

      const responses = await Promise.all(promises);
      const newProgramming: DriProgramming = { ...driProgramming };

      for (const { driId, response } of responses) {
        newProgramming[driId] = response.list;
      }

      setDriProgramming(newProgramming);

      for (const driId of driIds) {
        const timeout = setTimeout(() => {
          fetchScheds([driId]);
        }, retryInterval);
        timeouts.current[driId] = timeout;
      }
    } catch (error) {
      console.error(error);
      toast.error('Erro ao buscar programações.');
    }
  }, [driProgramming, retryInterval, timeouts]);

  const clearSavedProgramming = useCallback((driIds: string[]): void => {
    setDriProgramming((previousValues) => {
      if (driIds.length === 0) return {};
      const newProgramming = { ...previousValues };

      for (const driId of driIds) {
        delete newProgramming[driId];
        delete timeouts.current[driId];
      }

      return newProgramming;
    });
  }, []);

  const refetchScheds = useCallback(async (driIds: string[]): Promise<void> => {
    clearSavedProgramming(driIds);

    await fetchScheds(driIds);
  }, [clearSavedProgramming, fetchScheds]);

  const values = useMemo((): SchedsContextProps => ({
    fetchScheds,
    clearSavedProgramming,
    scheds: driProgramming,
    refetchScheds,
  }), [clearSavedProgramming, driProgramming, fetchScheds, refetchScheds]);

  useEffect(() => () => {
    for (const value of Object.values(timeouts.current)) {
      clearInterval(value);
    }
  }, []);

  return (
    <DriProgrammingContext.Provider value={values}>
      {children}
    </DriProgrammingContext.Provider>
  );
};
