export const FancoilIcon = ({ width, height, color }: { width?: number, height?: number, color?: string }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? 17}
    height={height ?? 17}
    fill="none"
    viewBox="0 0 18 17"
  >
    <rect
      width="2"
      height="2"
      x="2.5"
      y="6.5"
      fill="#fff"
      stroke={color ?? '#363BC4'}
      rx="0.5"
      transform="rotate(90 2.5 6.5)"
    />
    <rect
      width="2"
      height="2"
      x="2.5"
      y="10.5"
      fill="#fff"
      stroke={color ?? '#363BC4'}
      rx="0.5"
      transform="rotate(90 2.5 10.5)"
    />
    <path
      stroke={color ?? '#363BC4'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M5 9h2M5 7h2M9 9h2M13 9h2M9 7h2M13 7h2"
    />
    <path
      stroke={color ?? '#363BC4'}
      d="M2.5 15.5a1 1 0 0 1 1-1h13a1 1 0 1 1 0 2h-13a1 1 0 0 1-1-1ZM3.5 1A.5.5 0 0 1 4 .5h5a.5.5 0 0 1 .5.5v1.5h-6zM10.5 1a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v1.5h-6z"
    />
    <rect
      width="15"
      height="12"
      x="2.5"
      y="2.5"
      stroke={color ?? '#363BC4'}
      rx="1.5"
    />
  </svg>
);
