import { ReactElement } from 'react';
import { Button, ModalWindow } from 'components';
import { CloseIcon, ReturnIcon } from 'icons';
import { colors } from '~/styles/remoteControlColors';
import { FooterContainerModal, IconButton, LeavePageModalContent } from '../styles';
import { useTranslation } from 'react-i18next';

interface LeavePageModalProperties {
  open: boolean;
  onClose: () => void;
  onClickLeave: () => void;
}

export function LeavePageModal({
  open,
  onClose,
  onClickLeave,
}: LeavePageModalProperties): ReactElement | null {
  const isDesktop = window.matchMedia('(min-width: 1039px)').matches;
  const { t } = useTranslation();

  return open ? (
    <ModalWindow
      style={{
        padding: '0',
        overflowX: 'hidden',
        width: '512px',
      }}
      onClickOutside={() => {}}
      variant={!isDesktop ? 'mobile-rc' : undefined}
    >
      <LeavePageModalContent>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              <ReturnIcon />
              <h1 style={{
                color: colors.Blue02,
                fontWeight: '700',
                fontSize: '24px',
                lineHeight: '24px',
                margin: 0,
              }}
              >
                {t('sairDaPagina')}
              </h1>
            </div>
            <IconButton
              type="button"
              onClick={onClose}
            >
              <CloseIcon color={colors.Blue02} size="12" />
            </IconButton>
          </div>
          <p style={{
            fontSize: '16px',
            fontWeight: '500',
            color: colors.Grey,
          }}
          >
            {t('desejaRealmenteSairDaPagina')}
          </p>
        </div>
        <FooterContainerModal>
          <Button
            type="button"
            variant="rc-grey"
            onClick={onClose}
          >
            {t('cancelar')}
          </Button>
          <Button
            type="button"
            variant="rc-green"
            onClick={onClickLeave}
          >
            {t('sair')}
          </Button>
        </FooterContainerModal>
      </LeavePageModalContent>
    </ModalWindow>
  ) : null;
}
