type RefrigerateIconProps = {
  color?: string;
  size?: string;
}

export const Refrigerate = ({ color, size }: RefrigerateIconProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size ?? '14'}
    height={size ?? '14'}
    fill="none"
    viewBox="0 0 14 14"
  >
    <path
      fill={color ?? '#9C9C9C'}
      d="m12.63 8.67.561-.31-.62-1.123-.562.31c-.43.238-.812.556-1.124.936L8.317 7l2.568-1.483c.313.38.694.698 1.124.936l.562.31.62-1.123-.56-.31a2.6 2.6 0 0 1-.611-.467l1.547-.895-.642-1.11-1.546.892a2.6 2.6 0 0 1-.1-.762l.012-.642-1.283-.024-.012.642c-.009.491.075.98.248 1.44L7.675 5.888V2.922c.485-.08.951-.252 1.373-.506l.55-.33-.663-1.1-.55.331a2.6 2.6 0 0 1-.71.295V0H6.392v1.612a2.6 2.6 0 0 1-.71-.295l-.55-.33-.662 1.098.55.331c.42.254.886.426 1.372.506v2.966L3.823 4.404c.173-.46.258-.95.249-1.442l-.012-.641-1.284.024.012.641q.008.389-.1.763l-1.546-.89L.5 3.968l1.547.894q-.27.278-.61.467l-.562.31.621 1.123.562-.31c.43-.238.811-.556 1.124-.936L5.75 7 3.181 8.483a3.9 3.9 0 0 0-1.124-.936l-.561-.31-.62 1.123.56.31q.34.188.611.467L.5 10.033l.642 1.112 1.547-.894c.07.248.104.505.1.763l-.012.642 1.283.024.012-.642a3.9 3.9 0 0 0-.249-1.442l2.569-1.484v2.966c-.486.08-.951.252-1.373.506l-.55.33.663 1.1.55-.331c.22-.133.46-.232.71-.295V14h1.283v-1.612c.25.063.49.162.71.295l.55.33.662-1.098-.55-.331a3.9 3.9 0 0 0-1.372-.506V8.112l2.569 1.484c-.174.46-.258.95-.25 1.441l.013.642 1.283-.024-.012-.641q-.006-.39.101-.764l1.546.893.642-1.11-1.546-.895q.27-.279.609-.468"
    />
  </svg>
);
