type WifiConnectionProps = {
  size?: number;
  disabled?: boolean;
}

export const WifiConnection = ({ disabled, size }: WifiConnectionProps): JSX.Element => (
  <svg width={size ?? '17'} height={size ?? '14'} viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.12994 10.4313C6.90297 10.6591 6.74863 10.9491 6.68633 11.2646C6.62406 11.5801 6.65663 11.9069 6.77994 12.2039C6.90329 12.5009 7.11187 12.7547 7.37934 12.9332C7.64681 13.1117 7.96117 13.207 8.28274 13.207C8.60431 13.207 8.91869 13.1117 9.18613 12.9332C9.4536 12.7547 9.66218 12.5009 9.78549 12.2039C9.90884 11.9069 9.94146 11.5801 9.87915 11.2646C9.81688 10.9491 9.6625 10.6591 9.43553 10.4313C9.28486 10.2787 9.10533 10.1575 8.90746 10.0748C8.70955 9.99217 8.4972 9.94958 8.28274 9.94958C8.06827 9.94958 7.85592 9.99217 7.65805 10.0748C7.46014 10.1575 7.28065 10.2787 7.12994 10.4313Z" fill="white" {...(disabled && { opacity: '0.5' })} />
    <path
      d="M8.28186 6.569C7.62139 6.56674 6.96701 6.6953 6.35645 6.94721C5.74589 7.19911 5.19125 7.56936 4.7245 8.03668C4.54624 8.2156 4.44632 8.45803 4.44678 8.7106C4.44727 8.96321 4.54804 9.20525 4.72697 9.38354C4.90589 9.56183 5.14832 9.66172 5.40093 9.66126C5.6535 9.66081 5.89558 9.56 6.07383 9.38108C6.66002 8.79774 7.45331 8.47029 8.28028 8.47029C9.10724 8.47029 9.90057 8.79774 10.4867 9.38108C10.575 9.46987 10.6799 9.54042 10.7954 9.58871C10.911 9.63696 11.0349 9.662 11.1601 9.66239C11.2853 9.66274 11.4094 9.63847 11.5252 9.59089C11.6411 9.54331 11.7464 9.47339 11.8352 9.38513C11.924 9.29686 11.9946 9.19194 12.0428 9.07641C12.0911 8.96089 12.1161 8.83694 12.1165 8.71173C12.1168 8.58652 12.0926 8.46247 12.045 8.34662C11.9974 8.23082 11.9275 8.12547 11.8392 8.03668C11.3725 7.56936 10.8178 7.19911 10.2073 6.94721C9.59671 6.6953 8.94233 6.56674 8.28186 6.569Z"
      fill="white"
      {...(disabled && { opacity: '0.5' })}
    />
    <path d="M8.28254 3.43875C7.21008 3.4359 6.14766 3.64508 5.15632 4.05432C4.16501 4.46352 3.26437 5.06472 2.50623 5.82324C2.41796 5.91186 2.348 6.01696 2.30035 6.13259C2.2527 6.24823 2.22829 6.37214 2.22852 6.49721C2.22875 6.62224 2.25361 6.74605 2.30168 6.8615C2.34975 6.97699 2.42009 7.08185 2.50869 7.17011C2.59729 7.25838 2.70241 7.32836 2.81804 7.37598C2.93367 7.42364 3.05758 7.44804 3.18265 7.44783C3.30772 7.44759 3.43149 7.42272 3.54695 7.37468C3.66244 7.3266 3.76729 7.25626 3.85556 7.16765C5.03213 5.99713 6.62431 5.34004 8.28395 5.34004C9.94361 5.34004 11.5358 5.99713 12.7123 7.16765C12.8906 7.34622 13.1325 7.44664 13.3849 7.44685C13.5098 7.44692 13.6335 7.4224 13.749 7.37471C13.8645 7.32699 13.9694 7.25697 14.0578 7.1687C14.1462 7.08044 14.2164 6.97562 14.2643 6.8602C14.3122 6.74481 14.3369 6.62112 14.337 6.49619C14.3371 6.37125 14.3126 6.24752 14.2649 6.13207C14.2171 6.01661 14.1471 5.91165 14.0589 5.82324C13.3009 5.0645 12.4003 4.4632 11.4089 4.05397C10.4176 3.64473 9.35503 3.43566 8.28254 3.43875Z" fill="white" {...(disabled && { opacity: '0.5' })} />
    <path opacity="0.5" d="M16.2868 3.60542C14.1492 1.47452 11.3065 0.302002 8.282 0.302002C5.25753 0.302002 2.41553 1.47452 0.277592 3.60542C0.0993615 3.78434 -0.000494779 4.02677 1.84358e-06 4.27931C0.000494944 4.53185 0.101292 4.77389 0.280224 4.95211C0.459155 5.13033 0.701574 5.23018 0.954126 5.22969C1.20668 5.2292 1.44868 5.12839 1.62691 4.94947C3.3951 3.1904 5.78783 2.20294 8.282 2.20294C10.7762 2.20294 13.1689 3.1904 14.9371 4.94947C15.1153 5.12846 15.3573 5.2293 15.6099 5.22983C15.8625 5.23036 16.105 5.13051 16.284 4.95229C16.4629 4.77407 16.5638 4.53202 16.5643 4.27945C16.5648 4.02684 16.465 3.78441 16.2868 3.60542Z" fill="white" />
  </svg>
);
