type FanIconProps = {
  color?: string;
  size?: string;
}

export const FanRCIcon = ({
  color = '#9C9C9C',
  size,
}: FanIconProps): JSX.Element => (
  <svg width={size ?? '22'} height={size ?? '20'} viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.0004 14.1399C12.653 14.1399 13.9927 12.7967 13.9927 11.1399C13.9927 9.48304 12.653 8.13989 11.0004 8.13989C9.34776 8.13989 8.00806 9.48304 8.00806 11.1399C8.00806 12.7967 9.34776 14.1399 11.0004 14.1399Z" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.1616 8.63989C13.8817 7.10189 14.3146 5.59689 14.4612 4.12589C14.6807 2.49589 13.7012 1.13989 11.0001 1.13989C8.29906 1.13989 7.31957 2.49689 7.53901 4.12589C7.68563 5.59689 8.11852 7.10189 8.83867 8.63989" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.1663 14.8919C13.1338 16.2869 14.218 17.4149 15.4149 18.2779C16.7116 19.2839 18.3743 19.1109 19.7248 16.7659C21.0754 14.4209 20.3931 12.8919 18.877 12.2679C17.5325 11.6599 16.0164 11.2829 14.3267 11.1399" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.67307 11.1399C5.98441 11.2829 4.46831 11.6599 3.12477 12.2679C1.60667 12.8909 0.924423 14.4209 2.27495 16.7659C3.62548 19.1109 5.28721 19.2829 6.58487 18.2779C7.78179 17.4149 8.86601 16.2869 9.83452 14.8919" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
