import { User, WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

export const oidcConfig: AuthProviderProps = {
  // address to auth in client of keycloak
  // http://localhost:8080/realms/test/protocol/openid-connect/auth?client_id=test&redirect_uri=http://localhost:3000&response_type=code
  authority: process.env.REACT_APP_KEYCLOAK_REALM_URL as string,
  client_id: process.env.REACT_APP_KEYCLOAK_CLIENT_ID as string,
  redirect_uri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URI as string,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: (user: User | undefined) => {
    if (user) {
      localStorage.setItem('@diel:token', user.access_token);
    }
  },
};

export function getKeycloakData(): User | null {
  const oidcStorage = localStorage.getItem(
    `oidc.user:${process.env.REACT_APP_KEYCLOAK_REALM_URL}:${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`,
  );
  if (!oidcStorage) {
    return null;
  }

  return User.fromStorageString(oidcStorage);
}
