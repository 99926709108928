import moment from 'moment';

export function getDatesList(date: moment.Moment, numDays: number) {
  const dateList = [] as {
    mdate: moment.Moment;
    YMD: string;
    DMY: string;
    totalGreenAntCons: number;
    totalGreenAntInvoice: number;
    totalAirCondCons: number;
    savings_kWh: number;
  }[];

  for (let i = 0; i < numDays; i++) {
    const mdate = moment(date).add(i, 'days');
    dateList.push({
      mdate,
      DMY: mdate.format('DD/MM/YYYY'),
      YMD: mdate.format('YYYY-MM-DD'),
      totalGreenAntCons: 0,
      totalGreenAntInvoice: 0,
      totalAirCondCons: 0,
      savings_kWh: 0,
    });
  }

  return dateList;
}

/**
 * Converts a date string in `DD/MM/YYYY` format to `YYYY-MM-DD` format.
 *
 * @param {string} [str] - The input date string in `DD/MM/YYYY` format.
 * @returns {string|undefined} - The formatted date string in `YYYY-MM-DD` format, or `undefined` if the input is not provided.
 */
export function formatStringToDate(str?: string): string | undefined {
  return str ? `${str.substring(6, 10)}-${str.substring(3, 5)}-${str.substring(0, 2)}` : undefined;
}

/**
 * Converts an ISO date string in `YYYY-MM-DD` format to `DD/MM/YYYY` format.
 *
 * @param {string} [str] - The input ISO date string in `YYYY-MM-DD` format.
 * @returns {string|undefined} - The formatted date string in `DD/MM/YYYY` format, or `undefined` if the input is not provided.
 */
export function formatISODateToFormattedDateString(str?: string): string | undefined {
  return str ? `${str.substring(8, 10)}/${str.substring(5, 7)}/${str.substring(0, 4)}` : undefined;
}
