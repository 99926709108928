import { ReactElement } from 'react';
import { cutText } from 'helpers/formatText';
import { StyledLink } from '../../styles';
import ReactTooltip from 'react-tooltip';
import { Flex } from 'reflexbox';

interface TextComponentWithTooltipProps {
  text: string;
  link?: string;
  cutTextLength?: number;
}

export function TextComponentWithTooltip({ text, link, cutTextLength }: Readonly<TextComponentWithTooltipProps>): ReactElement {
  const newText = cutText(text, cutTextLength ?? 30);

  return (
    <>
      <div>
        {link ? (
          <StyledLink
            to={link}
            style={{
              marginLeft: '10px',
              textDecoration: 'underline',
            }}
            color="#8b8b8b"
            data-tip
            data-for={text}
          >
            {newText}
          </StyledLink>
        ) : newText}
      </div>
      {text.length >= (cutTextLength ?? 30) && (
      <ReactTooltip
        id={text}
        place="top"
        effect="solid"
        delayHide={100}
        offset={{ top: 0, left: 10 }}
        textColor="#000000"
        border
        backgroundColor="rgba(256, 256, 256, 1)"
      >
        <Flex flexWrap="nowrap" flexDirection="column" alignItems="left">
          <span
            style={{
              fontSize: '95%',
            }}
          >
            {text}
          </span>
        </Flex>
      </ReactTooltip>
      )}
    </>
  );
}
