type EconomySaveIconProps = {
  size?: number;
}

export const EconomySaveIcon = ({ size }: EconomySaveIconProps): JSX.Element => (
  <svg width={size ?? '76'} height={size ?? '89'} viewBox="0 0 76 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M58.8629 18.6488C58.6318 16.9016 58.1868 15.2189 57.545 13.6329C56.8112 11.8212 55.825 10.1385 54.6355 8.617C53.0781 6.62482 51.1677 4.92706 48.9941 3.60109C45.7893 1.6476 42.0348 0.5 38.0064 0.5C33.9759 0.5 30.2192 1.6476 27.0145 3.60109C24.8409 4.92706 22.9305 6.62697 21.373 8.617C20.1814 10.1385 19.1973 11.8191 18.4635 13.6329C17.8217 15.2189 17.3767 16.8995 17.1457 18.6488C17.028 19.5428 16.9446 20.4454 16.9446 21.3695C16.9446 22.126 16.9895 22.8932 17.0708 23.6626C17.2441 25.3302 17.6163 27.0151 18.1405 28.6785C18.6817 30.3977 19.3984 32.0826 20.2734 33.6944C21.1591 35.3277 22.1881 36.8922 23.3754 38.3063C23.4247 38.3815 23.4781 38.4524 23.5295 38.5169C23.5744 38.5728 23.63 38.6523 23.6792 38.7103C24.5499 39.8192 25.8571 41.7684 26.7856 43.7262H49.2957C50.1557 41.9016 51.3666 40.0191 52.3914 38.7103C52.4406 38.6458 52.4962 38.5706 52.5433 38.5104C52.6716 38.36 52.7807 38.1924 52.877 38.0075C53.9638 36.6708 54.9115 35.2138 55.7373 33.6944C56.6123 32.0826 57.3225 30.3956 57.8638 28.6785C58.3879 27.013 58.7623 25.3281 58.9377 23.6626C59.019 22.8911 59.0682 22.1238 59.0682 21.3674C59.0682 20.4433 58.9848 19.5385 58.8672 18.6467L58.8629 18.6488Z" fill="white" stroke="#0F0F36" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28.3198 49.1211V53.7566H47.6809V49.2543C47.7793 49.0953 47.837 48.9148 47.9098 48.7407H28.1658C28.2193 48.8675 28.2556 49.0008 28.3198 49.119V49.1211Z" fill="#363BC4" stroke="#363BC4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M31.6468 60.9828H33.3091L34.1049 61.8962C34.7296 62.6311 35.6452 63.0524 36.6122 63.0524H39.3848C40.3539 63.0524 41.2632 62.6311 41.8921 61.9005L42.6944 60.9807H44.3566C45.7921 60.9807 47.0052 60.0544 47.4673 58.7693H28.5255C28.9898 60.0523 30.2028 60.9807 31.6425 60.9807L31.6468 60.9828Z" fill="#363BC4" stroke="#363BC4" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28.3199 57.6423C28.3199 58.0399 28.4034 58.4181 28.5296 58.7727H47.4713C47.5997 58.4181 47.681 58.0399 47.681 57.6423V53.7568H28.3199V57.6423Z" fill="#0F0F36" stroke="#0F0F36" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M27.8616 47.404C27.8616 47.8854 27.99 48.326 28.1654 48.7386H47.9094C48.0827 48.3195 48.2089 47.8768 48.2089 47.404C48.2089 46.4004 48.6582 45.0809 49.2957 43.7249H26.7855C27.4145 45.0487 27.8638 46.366 27.8638 47.404H27.8616Z" fill="#0F0F36" stroke="#0F0F36" strokeLinecap="round" strokeLinejoin="round" />
    <path fillRule="evenodd" clipRule="evenodd" d="M37.8863 39.47C37.8178 39.47 37.7515 39.47 37.6809 39.4678C37.5076 39.4678 37.3365 39.4614 37.1696 39.4549C24.2501 38.9628 24.924 27.4589 24.924 27.4589C34.5532 26.4252 37.0519 34.4003 37.6809 37.8904C37.7087 38.0451 37.7344 38.1956 37.7558 38.331C37.8627 39.038 37.8841 39.4721 37.8841 39.4721L37.8863 39.47Z" fill="#00FF9B" />
    <path d="M38.8319 34.0622C38.633 34.0687 38.434 34.0751 38.2286 34.0751C38.1901 34.0772 38.1495 34.0772 38.111 34.0772C38.111 34.0772 38.1302 33.6818 38.2286 33.0307C38.2308 32.9984 38.2372 32.9683 38.2415 32.9361C38.7378 29.7383 41.0119 20.983 51.0732 22.064C51.0732 22.064 51.7493 33.5722 38.8319 34.0601V34.0622Z" fill="#00FF9B" />
    <path d="M38.3719 26.0318C38.3249 26.0834 38.2778 26.1328 38.2307 26.1844C38.1173 26.3091 37.9975 26.4294 37.8777 26.5519C37.8777 26.5519 37.805 26.4724 37.6831 26.3198C37.5568 26.1672 37.3793 25.9394 37.1696 25.6472C35.2677 23.0317 30.7195 15.2285 38.5923 8.8501C38.5923 8.8501 47.171 16.5115 38.3741 26.0318H38.3719Z" fill="#00FF9B" />
    <path d="M37.8866 15.7209V44.6194" stroke="#0F0F36" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M47.9099 24.718L38.1139 34.0772" stroke="#0F0F36" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28.1658 30.1667L37.6816 39.4657" stroke="#0F0F36" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.2516 59.4449C17.428 59.1785 16.7648 58.5574 16.4396 57.7515L11.2602 44.8765C10.5799 43.1873 8.98398 42.0096 7.17195 41.9688C7.14842 41.9688 7.12489 41.9688 7.10136 41.9688C5.9889 41.9623 5.17809 43.0326 5.46904 44.1135L12.024 68.5183C12.2914 69.5133 12.7171 70.446 13.2755 71.2906C14.3559 67.0913 15.8662 62.9178 18.703 59.5932L18.2559 59.4492L18.2516 59.4449Z" fill="white" stroke="#0F0F36" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.4618 79.4389L20.2501 83.4512H34.7141L34.528 79.8429C34.4531 78.3837 34.4767 76.9224 34.5195 75.461C34.712 68.9021 30.5745 62.8997 24.2356 60.8495L14.1144 57.5744C12.9934 57.2112 12.0906 56.3688 11.6499 55.2727L6.65453 42.8555C5.97422 41.1663 4.37827 39.9886 2.56624 39.9478C2.54271 39.9478 2.51918 39.9478 2.49564 39.9478C1.38319 39.9413 0.572374 41.0116 0.863325 42.0925L4.82325 56.8372C7.09309 65.2895 12.3666 72.6113 19.6446 77.4231C20.3057 77.8615 20.6309 78.6631 20.4618 79.4389Z" fill="white" stroke="#0F0F36" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M28.8776 68.9813C28.4412 68.4763 29.5537 63.901 18.2002 58.5992C17.0257 56.3685 15.802 53.7552 13.7461 52.1972C11.9383 50.8261 9.29408 52.253 9.69841 54.5912C10.0856 56.8284 16.2362 68.3259 16.8823 69.4778" fill="white" />
    <path d="M28.8776 68.9813C28.4412 68.4763 29.5537 63.901 18.2002 58.5992C17.0257 56.3685 15.802 53.7552 13.7461 52.1972C11.9383 50.8261 9.29408 52.253 9.69841 54.5912C10.0856 56.8284 16.2362 68.3259 16.8823 69.4778" stroke="#0F0F36" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M36.0332 82.3975H18.2101C17.6578 82.3975 17.2101 82.8452 17.2101 83.3975V87.2171C17.2101 87.7694 17.6578 88.2171 18.2101 88.2171H36.0332C36.5855 88.2171 37.0332 87.7694 37.0332 87.2171V83.3975C37.0332 82.8452 36.5855 82.3975 36.0332 82.3975Z" fill="#363BC4" />
    <path d="M57.7479 59.4447C58.5716 59.1782 59.2348 58.5571 59.5599 57.7512L64.7393 44.8762C65.4196 43.1871 67.0155 42.0094 68.8276 41.9685C68.8511 41.9685 68.8746 41.9685 68.8982 41.9685C70.0106 41.9621 70.8214 43.0323 70.5305 44.1133L63.9755 68.518C63.7081 69.513 63.2824 70.4457 62.724 71.2903C61.6437 67.091 60.1333 62.9176 57.2965 59.593L57.7436 59.449L57.7479 59.4447Z" fill="white" stroke="#0F0F36" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M55.5383 79.4389L55.7501 83.4512H41.286L41.4721 79.8429C41.547 78.3837 41.5235 76.9224 41.4807 75.461C41.2881 68.9021 45.4256 62.8997 51.7645 60.8495L61.8857 57.5744C63.0068 57.2112 63.9096 56.3688 64.3503 55.2727L69.3456 42.8555C70.0259 41.1663 71.6219 39.9886 73.4339 39.9478C73.4575 39.9478 73.481 39.9478 73.5045 39.9478C74.617 39.9413 75.4278 41.0116 75.1368 42.0925L71.1769 56.8372C68.9071 65.2895 63.6336 72.6113 56.3555 77.4231C55.6945 77.8615 55.3693 78.6631 55.5383 79.4389Z" fill="white" stroke="#0F0F36" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M47.1222 68.9813C47.5586 68.4763 46.4462 63.901 57.7996 58.5992C58.9741 56.3685 60.1979 53.7552 62.2538 52.1972C64.0615 50.8261 66.7057 52.253 66.3014 54.5912C65.9142 56.8284 59.7636 68.3259 59.1175 69.4778" fill="white" />
    <path d="M47.1222 68.9813C47.5586 68.4763 46.4462 63.901 57.7996 58.5992C58.9741 56.3685 60.1979 53.7552 62.2538 52.1972C64.0615 50.8261 66.7057 52.253 66.3014 54.5912C65.9142 56.8284 59.7636 68.3259 59.1175 69.4778" stroke="#0F0F36" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M39.9674 88.2144H57.7905C58.3428 88.2144 58.7905 87.7666 58.7905 87.2144V83.3947C58.7905 82.8424 58.3428 82.3947 57.7905 82.3947H39.9674C39.4151 82.3947 38.9674 82.8424 38.9674 83.3947V87.2144C38.9674 87.7666 39.4151 88.2144 39.9674 88.2144Z" fill="#363BC4" />
  </svg>
);
