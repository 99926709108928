import { ReactElement, useEffect } from 'react';
import { DriScheduleList } from 'pages/Analysis/SchedulesModals/DRI_ScheduleList';
import {
  DriException, DriSchedule, EditScheduleModal, ModalEditSchedule,
} from 'pages/Analysis/SchedulesModals/DRI_ScheduleModal';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useStateVar } from 'helpers/useStateVar';
import { DriSchedModeOpts, ScheduleInfo } from 'pages/Analysis/SchedulesModals/DRI_Shedule';
import { getKeyByValue } from 'pages/Analysis/Integrations/IntegrRealTime/DriContents';
import {
  checkExceptionDate, checkTimeConflict, checkWeekDayCommon, generateDaysObject, getSchedTimesValues,
} from 'helpers/driSchedule';
import { generateDefaultDevInfo, generateDefaultDriCfg } from '../constants';
import { SelectDriTypes } from '../types';

interface DriSchedulesContentProps {
  clientId: number;
  openAddModal: boolean;
  onCloseModal: () => void;
  schedules: DriSchedule[];
  exceptions: DriException[];
  actions: {
    onAddSched: (sched) => Promise<void>,
    onUpdateSched: (sched) => Promise<void>,
    onDeleteSched: (sched) => void,
  }
  isException: boolean;
  onChangeShowException: (value: boolean) => void;
  selectedDevType: SelectDriTypes;
}

export function DriSchedulesContent({
  actions,
  clientId,
  exceptions,
  schedules,
  openAddModal,
  onCloseModal,
  isException,
  onChangeShowException,
  selectedDevType,
}: Readonly<DriSchedulesContentProps>): ReactElement {
  const { t } = useTranslation();

  const [state, render, setState] = useStateVar({
    modalEditSchedule: null as ModalEditSchedule | null,
  });

  function getSchedExceptionValues(item?: ScheduleInfo) {
    return {
      isException,
      exceptionDate: item?.EXCEPTION_DATE ?? '',
      repeatYearly: item?.EXCEPTION_REPEAT_YEARLY === '1',
    };
  }

  function getSchedSetpointValues(item?: ScheduleInfo) {
    return {
      useSetpoint: !!item?.SETPOINT && Number(getKeyByValue(DriSchedModeOpts, item.MODE)) !== 1,
      setpointValue: item?.SETPOINT
        ? String(item.SETPOINT)
        : '24',
    };
  }

  function getSchedModeValues(item?: ScheduleInfo) {
    return {
      mode: item
        ? Number(getKeyByValue(DriSchedModeOpts, item.MODE))
        : undefined,
    };
  }

  function editAddProgramming(item?: ScheduleInfo) {
    try {
      const days = item && JSON.parse(item.DAYS);
      state.modalEditSchedule = {
        schedId: item?.SCHED_ID,
        addEdit: item ? 'Edit' : 'Add',
        name: item?.NAME ?? '',
        active: (item ? item.ACTIVE === '1' : true),
        operation: !!(item && item.OPERATION === '1'),
        ...getSchedTimesValues(item),
        selectedDays: !isException ? generateDaysObject(days) : {},
        ...getSchedExceptionValues(item),
        ...getSchedSetpointValues(item),
        ...getSchedModeValues(item),
      };
      render();
    } catch (err) {
      console.log(err);
      toast.error(t('erro'));
    }
  }

  function validateException(): boolean {
    if (!state.modalEditSchedule) {
      return false;
    }

    for (const exception of exceptions) {
      if (exception.ACTIVE === '0') { continue; }
      if (state.modalEditSchedule.schedId !== undefined && state.modalEditSchedule.schedId === exception.SCHED_ID) { continue; }
      if (!checkExceptionDate(exception, {
        EXCEPTION_DATE: state.modalEditSchedule.exceptionDate,
        EXCEPTION_REPEAT_YEARLY: state.modalEditSchedule.repeatYearly ? '1' : '0',
      })) { continue; }
      if (checkTimeConflict({
        startTime: exception.BEGIN_TIME,
        endTime: exception.END_TIME,
      }, {
        startTime: state.modalEditSchedule.start_time,
        endTime: state.modalEditSchedule.end_time,
      })) {
        const msgError = t('erroSalvarExcecoes', {
          value1: exception.NAME,
          value2: state.modalEditSchedule.name,
        });
        toast.error(msgError);
        return false;
      }
    }
    return true;
  }

  function validateSchedule(): boolean {
    if (!state.modalEditSchedule) {
      return false;
    }

    const {
      selectedDays, start_time, end_time, name, schedId,
    } = state.modalEditSchedule;

    for (const schedule of schedules) {
      if (!schedule.ACTIVE) { continue; }
      if (schedId !== undefined && schedId === schedule.SCHED_ID) { continue; }
      if (!checkWeekDayCommon(JSON.parse(schedule.DAYS), selectedDays)) { continue; }
      if (checkTimeConflict(
        { startTime: schedule.BEGIN_TIME, endTime: schedule.END_TIME },
        { startTime: start_time, endTime: end_time },
      )
      ) {
        const msgError = t('erroSalvarProgramacoesHorarias', {
          value1: name,
          value2: schedule.NAME,
        });
        toast.error(msgError);
        return false;
      }
    }
    return true;
  }

  function validateSchedules(): boolean {
    if (!state.modalEditSchedule) {
      return false;
    }

    if (!state.modalEditSchedule.active) {
      return true;
    }

    return isException ? validateException() : validateSchedule();
  }

  function onCloseAddEditModal(): void {
    setState({ modalEditSchedule: undefined });
    onCloseModal();
  }

  useEffect(() => {
    if (openAddModal) {
      editAddProgramming();
    }
  }, [openAddModal]);

  return (
    <>
      <EditScheduleModal
        devInfo={generateDefaultDevInfo(clientId, selectedDevType)}
        isException={isException}
        modalEditSchedule={state.modalEditSchedule ?? undefined}
        onClose={onCloseAddEditModal}
        open={!!state.modalEditSchedule}
        validateSchedule={validateSchedules}
        onAddSchedule={actions.onAddSched}
        onUpdateSchedule={actions.onUpdateSched}
        hideRepeatYearly
      />
      <DriScheduleList
        driCfg={generateDefaultDriCfg(selectedDevType)}
        exceptions={exceptions}
        schedules={schedules}
        onDelete={actions.onDeleteSched}
        onAddEdit={editAddProgramming}
        clientId={clientId}
        onChangeShowException={onChangeShowException}
        size="fixed"
        hide={{
          add: true,
        }}
      />
    </>
  );
}
