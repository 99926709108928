import { ReactElement } from 'react';
import { Box, Flex } from 'reflexbox';
import { Button, ModalWindow } from '~/components';
import { SmallTrashIcon } from '~/icons/Trash';
import { UnderlineBtn } from '../../styles';
import { useTranslation } from 'react-i18next';

interface ClearAllAddedModalProps {
  isException: boolean;
  open: boolean;
  onClose: () => void;
  onClickClearAll: () => void;
}

export function ClearAllAddedModal({
  isException,
  onClose,
  onClickClearAll,
  open,
}: ClearAllAddedModalProps): ReactElement | null {
  const { t } = useTranslation();

  return open ? (
    <ModalWindow
      style={{ padding: '0', overflowX: 'hidden', width: '455px' }}
      borderTop
      onClickOutside={onClose}
    >
      <Flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        padding="80px"
      >
        <Box width="100%">
          <Flex>
            <SmallTrashIcon color="#DC0E01" />
            <h4 style={{ fontWeight: 'bold', marginLeft: '10px' }}>
              {isException
                ? t('botaoLimparExcecao') : t('botaoLimparProgramacao') }
            </h4>
          </Flex>
          <p style={{ color: '#8b8b8b', hyphens: 'auto' }}>
            {t('confirmacaoLimparTudoPt1')}
                  &nbsp;
            {isException
              ? t('excecoes').toLowerCase() : t('programacoes').toLowerCase()}
                  &nbsp;
            {t('confirmacaoLimparTudoPt2')}
                  &nbsp;
            <span style={{ fontWeight: 'bold' }}>
              {t('confirmacaoLimparTudoPt3')}
            </span>
                  &nbsp;
            {t('confirmacaoLimparTudoPt4')}
          </p>
          <Flex
            marginTop="40px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              style={{ width: '200px' }}
              variant="secondary"
              onClick={onClickClearAll}
            >
              {t('limpar').toUpperCase()}
            </Button>
            <UnderlineBtn onClick={onClose}>
              {t('cancelar')}
            </UnderlineBtn>
          </Flex>
        </Box>
      </Flex>
    </ModalWindow>
  ) : null;
}
