import {
  ReactElement, useEffect, useMemo, useState,
} from 'react';
import { colors } from 'styles/remoteControlColors';
import {
  PowerButton, SetpointButton, SetpointControllerContent, SetpointLabel,
} from '../styles';
import { AddIcon, MinusRCIcon, PowerRCIcon } from '~/icons';
import { useCalculateStrokeSetpoint } from '../hooks/useCalculateStrokeSetpoint';

interface SetpointControllerProps {
  readonly limits: {
    tMin: number;
    tMax: number;
  };
  readonly power: boolean | undefined;
  readonly currentSetpoint: number | undefined;
  readonly onClickPower: () => void;
  readonly onChangeSetpoint: (setpoint: number | undefined) => void;
  readonly availableSetpoints?: number[];
  readonly disabled?: boolean;
  readonly disabledSetpoint: boolean;
}

interface SetpointVisualizerProps {
  readonly limits: {
    tMin: number;
    tMax: number;
  };
  readonly currentSetpoint: number | undefined;
  readonly availableSetpoints?: number[];
}

function SetpointVisualizer({
  currentSetpoint,
  limits,
  availableSetpoints,
}: SetpointVisualizerProps): ReactElement {
  const { setpointStrokeDashArray } = useCalculateStrokeSetpoint(currentSetpoint, limits, availableSetpoints);

  return (
    <svg width="224" height="210" viewBox="0 0 224 210" style={{ position: 'absolute' }}>
      {/* <!-- Detalhe contorno do círculo --> */}
      <circle
        cx="112"
        cy="105"
        r="98"
        fill="none"
        stroke={colors.Grey04}
        strokeWidth="1"
        strokeDasharray="520 95.4"
        transform="rotate(119 112 105)"
        strokeLinecap="round"
      />

      {/* <!-- Fundo do círculo --> */}
      <circle
        cx="112"
        cy="105"
        r="80"
        fill="none"
        stroke={colors.Grey04}
        strokeWidth="8"
        strokeDasharray="401.63 130.77"
        transform="rotate(126 112 105)"
        strokeLinecap="round"
      />

      {/* <!-- Círculo de progresso --> */}
      {!!currentSetpoint && (
        <circle
          cx="112"
          cy="105"
          r="80"
          fill="none"
          stroke={colors.Blue}
          strokeWidth="8"
          strokeDasharray={setpointStrokeDashArray}
          transform="rotate(126 112 105)"
          strokeLinecap="round"
          style={{
            transition: 'stroke-dasharray 100ms linear',
          }}
        />
      )}
    </svg>
  );
}

export function SetpointController({
  availableSetpoints,
  currentSetpoint,
  power,
  limits,
  disabled,
  onClickPower,
  onChangeSetpoint,
  disabledSetpoint,
}: SetpointControllerProps): ReactElement {
  const [setpoint, setSetpoint] = useState(currentSetpoint);
  const availableSetpointsFilter = availableSetpoints?.filter((item) => item >= limits?.tMin && item <= limits?.tMax);

  const hasAvailableSetpoints = useMemo(() => (availableSetpointsFilter?.length ?? 0) > 0, [availableSetpointsFilter]);

  const minSetpoint = hasAvailableSetpoints && availableSetpointsFilter ? availableSetpointsFilter[0] : limits.tMin;
  const maxSetpoint = hasAvailableSetpoints && availableSetpointsFilter ? availableSetpointsFilter[availableSetpointsFilter.length - 1] : limits.tMax;

  const disabledButtons = !power || disabled || !setpoint;

  function isSetpointOutRange(limit: 'min' | 'max'): boolean {
    if (!setpoint) return false;
    if (hasAvailableSetpoints && availableSetpointsFilter) {
      return limit === 'max' ? setpoint > availableSetpointsFilter[availableSetpointsFilter.length - 1] : setpoint < availableSetpointsFilter[0];
    }
    return limit === 'max' ? setpoint > limits.tMax : setpoint < limits.tMin;
  }

  function onClickDecreaseSetpoint(): void {
    if (!setpoint) return;

    if (hasAvailableSetpoints && availableSetpointsFilter) {
      const foundCurrentIndex = availableSetpointsFilter.findIndex((item) => item === setpoint);
      if (foundCurrentIndex > 0) {
        const newSetpoint = availableSetpointsFilter[foundCurrentIndex - 1];
        setSetpoint(newSetpoint);
        onChangeSetpoint(newSetpoint);
      }
      return;
    }

    if (setpoint - 1 >= limits.tMin) {
      setSetpoint((previousSetpoint) => {
        const newSetpoint = (previousSetpoint ? previousSetpoint - 1 : previousSetpoint);

        onChangeSetpoint(newSetpoint);
        return newSetpoint;
      });
    }
  }

  function onClickIncreaseSetpoint(): void {
    if (!setpoint) return;

    if (isSetpointOutRange('min')) {
      setSetpoint(minSetpoint);
      onChangeSetpoint(minSetpoint);
      return;
    }

    if (isSetpointOutRange('max')) {
      setSetpoint(maxSetpoint);
      onChangeSetpoint(maxSetpoint);
      return;
    }

    if (hasAvailableSetpoints && availableSetpointsFilter) {
      const foundCurrentIndex = availableSetpointsFilter.findIndex((item) => item === setpoint);
      if (foundCurrentIndex >= 0 && foundCurrentIndex !== availableSetpointsFilter.length - 1) {
        const newSetpoint = availableSetpointsFilter[foundCurrentIndex + 1];
        setSetpoint(newSetpoint);
        onChangeSetpoint(newSetpoint);
      }
      return;
    }

    if (setpoint + 1 <= limits.tMax) {
      setSetpoint((previousSetpoint) => {
        const newSetpoint = (previousSetpoint ? previousSetpoint + 1 : previousSetpoint);

        onChangeSetpoint(newSetpoint);
        return newSetpoint;
      });
    }
  }

  const disabledIncreaseButton = disabledButtons || setpoint >= maxSetpoint || disabledSetpoint;
  const disabledDecreaseButton = disabledButtons || setpoint <= minSetpoint || disabledSetpoint;

  useEffect(() => {
    if (currentSetpoint && currentSetpoint !== setpoint) {
      setSetpoint(currentSetpoint);
    }
  }, [currentSetpoint]);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <SetpointButton
        onClick={onClickDecreaseSetpoint}
        type="button"
        disabled={disabledDecreaseButton}
      >
        <MinusRCIcon color={disabledDecreaseButton ? colors.Grey : colors.Blue} />
      </SetpointButton>
      <SetpointControllerContent>
        <SetpointVisualizer
          currentSetpoint={power && !isSetpointOutRange('min') && !isSetpointOutRange('max') && !disabledSetpoint ? setpoint : undefined}
          limits={limits}
          availableSetpoints={availableSetpointsFilter}
        />
        <SetpointLabel color={disabledSetpoint ? colors.Grey : colors.Blue}>
          {power && setpoint && !disabledSetpoint ? `${setpoint}ºC` : '-'}
        </SetpointLabel>
        <PowerButton
          type="button"
          style={{ marginTop: 'auto' }}
          disabled={power === undefined || disabled}
          onClick={onClickPower}
          off={!!power}
        >
          <PowerRCIcon />
        </PowerButton>
      </SetpointControllerContent>
      <SetpointButton
        onClick={onClickIncreaseSetpoint}
        type="button"
        disabled={disabledIncreaseButton}
      >
        <AddIcon color={disabledIncreaseButton ? colors.Grey : colors.Blue} />
      </SetpointButton>
    </div>
  );
}
