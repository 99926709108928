import {
  ReactElement, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  BlockedMessageContainer,
  ContainerAutomaticMachine, ContainerPage, CurrentTemperatureEnvironmentContainer, EconomySaveInfoContainer, LimitTemperatureContainer, LoaderContainer, ModeMachineContainer, RecommendedTemperatureContainer, ScheduleBadgeContainer, ScheduleBadgesContainer, ScheduleHoursContainer, ScheduleHoursTitle, SetpointContainer,
} from './styles';
import { useTranslation } from 'react-i18next';
import { getUserProfile } from 'helpers/userProfile';
import {
  AutomaticMode, CalendarSimpleIcon, InfoIconCR, Refrigerate, WatchIcon, EconomySaveIcon,
  FanRCIcon,
} from 'icons';
import { Button, Loader } from 'components';
import { useHistory, useParams } from 'react-router-dom';
import { colors } from 'styles/remoteControlColors';
import { ToggleSwitchMini } from 'components/ToggleSwitch';
import moment from 'moment-timezone';
import { SetpointController } from './components/SetpointController';
import { SaveChangesModal } from './components/SaveChangesModal';
import { toast, ToastOptions } from 'react-toastify';
import { getIrCommandsList, getMachineInfo, setMachineConfig } from './helpers';
import { ApiParams, ApiResps } from '~/providers';
import { EnvironmentTemperatureItem } from './components/EnvironmentTemperatureItem';
import { HeaderMachine } from './components/HeaderMachine';
import { identifyDutIrCommands } from '~/pages/Analysis/DUTs/EnvironmentRealTime';
import {
  AutomaticMachineActivateProps, ButtonOperationProps, Operation, ScheduleBadgeProps,
} from './types';

function AutomaticMachineActivate({
  checked,
  onClickCheck,
}: AutomaticMachineActivateProps): ReactElement {
  const isDesktop = window.matchMedia('(min-width: 1039px)').matches;
  const { t } = useTranslation();

  return (
    <ContainerAutomaticMachine>
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <AutomaticMode />
        <h2 style={{ margin: 0 }}>{t('modoAutomatico')}</h2>
      </div>
      {checked && isDesktop && (
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <InfoIconCR />
          <p style={{ margin: 0 }}>{t('ativarModoAutomaticoInfo')}</p>
        </div>
      )}
      <ToggleSwitchMini
        checked={checked}
        onClick={onClickCheck}
        style={{ marginLeft: 'auto' }}
        variant="remote-control"
      />
    </ContainerAutomaticMachine>
  );
}

function ScheduleBadge({
  beginTime,
  endTime,
}: ScheduleBadgeProps): ReactElement {
  function formatTime(time: string): string {
    const splitTime = time.split(':');

    return `${parseInt(splitTime[0], 10)}h${splitTime[1]}`;
  }

  return (
    <ScheduleBadgeContainer>
      <WatchIcon color={colors.Blue02} size={16} />
      <p>
        {formatTime(beginTime)}
        {' '}
        -
        {' '}
        {formatTime(endTime)}
      </p>
    </ScheduleBadgeContainer>
  );
}

function ButtonOperation({
  currentOperation,
  disabled,
  onClickModality,
  operation,
}: ButtonOperationProps): ReactElement {
  const { t } = useTranslation();

  const Icon = useMemo(() => {
    if (operation === 'cool') return Refrigerate;
    return FanRCIcon;
  }, [operation]);

  return (
    <Button
      type="button"
      variant={currentOperation === operation ? 'rc-blue-gradient' : 'rc-grey'}
      disabled={disabled}
      onClick={onClickModality}
    >
      <div style={{
        display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <Icon color={currentOperation === operation && !disabled ? colors.White : colors.Grey} size="24" />
        <span style={{ fontWeight: '500', fontSize: '14px' }}>{t(operation === 'cool' ? 'resfriar' : 'ventilar')}</span>
      </div>
    </Button>
  );
}

function BlockedMessageContent(): ReactElement {
  const { t } = useTranslation();
  return (
    <BlockedMessageContainer>
      <h1>{t('operacaoLivre')}</h1>
      <div>
        <InfoIconCR />
        <p>{t('descricaoOperacaoLivre')}</p>
      </div>
    </BlockedMessageContainer>
  );
}

export function MachineProfileCR(): ReactElement {
  const profile = getUserProfile();
  const isDesktop = window.matchMedia('(min-width: 1039px)').matches;

  const [openSaveChangesModal, setOpenSaveChangesModal] = useState(false);
  const [checkAutomaticMode, setCheckAutomaticMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [environments, setEnvironments] = useState<{ id: number, name: string, dutCode: string }[]>([]);
  const [machineInfo, setMachineInfo] = useState<{name: string; type: string, deviceCode: string}>();
  const [limits, setLimits] = useState<{tMin: number, tMax: number}>();
  const [currentOperation, setCurrentOperation] = useState<Operation>();
  const [currentSetpoint, setCurrentSetpoint] = useState<number>();
  const [automationIntervals, setAutomationIntervals] = useState<{startTime: string, endTime: string}[]>([]);
  const [defaultValues, setDefaultValues] = useState<ApiResps['/remote-control/get-machine-info']>();
  const [availableSetpoints, setAvailableSetpoints] = useState<{id: string, setpoint: number}[]>([]);
  const firstRender = useRef<boolean>(false);
  const hasEditedValuesRef = useRef<boolean>(false);
  const history = useHistory<{
    MACHINE_NAME: string,
    MACHINE_TYPE: string,
    deviceCode: string
  } | {}>();
  const { machineId } = useParams<{ machineId: string }>();
  const { t } = useTranslation();

  const positionToastByScreen: ToastOptions = {
    position: isDesktop ? 'top-right' : 'bottom-right',
  };

  function goToMachineList(): void {
    history.push('/controle-remoto');
  }

  function isValidChanges(): boolean {
    if (!defaultValues) {
      toast.error(t('naoFoiPossivelSalvarAlteracoes'), positionToastByScreen);
      onCloseSaveChangesModal();
      return false;
    }

    if (!checkAutomaticMode && currentSetpoint && currentOperation === 'cool') {
      if (limits && (limits.tMax < currentSetpoint || limits.tMin > currentSetpoint)) {
        toast.error(t('setpointEscolhidoNaoEstaLimites'), positionToastByScreen);
        return false;
      }

      if (availableSetpoints && availableSetpoints.length > 0) {
        if (!availableSetpoints.find((item) => item.setpoint === currentSetpoint)) {
          toast.error(t('comandoIRNaoExiste'), positionToastByScreen);
          return false;
        }
      }
    }

    return true;
  }

  async function saveChanges(): Promise<boolean> {
    if (!defaultValues) {
      return false;
    }

    const idIRSetpoint = availableSetpoints.length > 0 && currentSetpoint
      ? availableSetpoints.find((item) => item.setpoint === currentSetpoint)?.id
      : undefined;

    const reqParams: ApiParams['/remote-control/set-config'] = {
      MACHINE_ID: Number(machineId),
      DEV_AUT_CODE: defaultValues.DEV_AUT_CODE,
      ECONOMY_ENABLED: checkAutomaticMode,
      ...(checkAutomaticMode ? {
        OPERATION: undefined,
        IR_ID_COOL: undefined,
        SETPOINT: undefined,
      } : {
        SETPOINT: currentSetpoint,
        OPERATION: currentOperation,
        IR_ID_COOL: idIRSetpoint,
      }),
    };
    const response = await setMachineConfig(reqParams);

    const isAutomaticModeValid = response?.ECONOMY_ENABLED === reqParams.ECONOMY_ENABLED;
    const isOperationValid = response?.OPERATION === reqParams.OPERATION;
    const isSetpointValid = response?.SETPOINT === reqParams.SETPOINT;

    return isAutomaticModeValid && isOperationValid && isSetpointValid;
  }

  function onClickSaveModal(): void {
    if (!hasEditedValues()) {
      return goToMachineList();
    }

    if (!isValidChanges()) {
      onCloseSaveChangesModal();
      return;
    }

    setLoading(true);

    saveChanges().then((success) => {
      setLoading(false);
      if (success) {
        toast.success(t('alteracoesSalvasComSucesso'), positionToastByScreen);
        goToMachineList();
      } else {
        toast.error(t('naoFoiPossivelSalvarAlteracoes'), positionToastByScreen);
        onCloseSaveChangesModal();
      }
    }).catch(() => {
      setLoading(false);
      onCloseSaveChangesModal();
    });
  }

  function onClickSave(): void {
    setOpenSaveChangesModal(true);
  }

  function onCloseSaveChangesModal(): void {
    setOpenSaveChangesModal(false);
  }

  function renderFormattedDate(): string {
    const momentDate = moment(new Date());
    return `${momentDate.format('ddd')}, ${momentDate.format('DD/MM/YYYY')}`;
  }

  function onClickCheckAutomaticMode(): void {
    hasEditedValuesRef.current = true;
    const newValue = !checkAutomaticMode;
    if (!newValue) {
      if (!currentOperation) {
        setCurrentOperation('cool');
      }
    } else {
      setCurrentOperation(defaultValues?.OPERATION);
      setCurrentSetpoint(defaultValues?.SETPOINT);
    }

    setCheckAutomaticMode(newValue);
  }

  function onClickModality(mode: 'cool' | 'fan'): void {
    hasEditedValuesRef.current = true;
    if (mode === 'cool' && defaultValues?.SETPOINT) {
      setCurrentSetpoint(defaultValues.SETPOINT);
    }

    if (mode === 'fan') {
      setCurrentSetpoint(undefined);
    }
    setCurrentOperation(mode);
  }

  function onClickPower(): void {
    hasEditedValuesRef.current = true;
    setCurrentOperation((previousValue) => {
      if (previousValue === 'disabled') {
        if (defaultValues?.SETPOINT) setCurrentSetpoint(defaultValues.SETPOINT);
        return 'cool';
      }
      setCurrentSetpoint(undefined);
      return 'disabled';
    });
  }

  function onChangeSetpoint(newSetpoint: number | undefined): void {
    hasEditedValuesRef.current = true;
    setCurrentSetpoint(newSetpoint);
  }

  async function getMachine(needMachineInfo: boolean): Promise<void> {
    setLoading(true);
    const permissionUnit = profile.permissions.PER_UNIT?.[0];

    if (!permissionUnit) return;

    const unitId = permissionUnit.units[0];

    const response = await getMachineInfo({
      MACHINE_ID: Number(machineId),
      UNIT_ID: unitId,
    });

    if (!response) {
      toast.error(t('naoFoiPossivelBuscarInfoMaquina'), positionToastByScreen);
      return;
    }

    setDefaultValues(response);

    if (needMachineInfo) {
      setMachineInfo({
        name: response.MACHINE_NAME,
        type: response.MACHINE_TYPE,
        deviceCode: response.DEV_AUT_CODE,
      });
    }

    setEnvironments(response.ENVIRONMENTS.map((env) => ({
      id: env.ENVIRONMENT_ID,
      name: env.ENVIRONMENT_NAME,
      dutCode: env.DUT_CODE,
    })));
    setLimits({
      tMax: response.MAXIMUM_SETPOINT,
      tMin: response.MINIMUM_SETPOINT,
    });
    setCurrentOperation(response.OPERATION ?? undefined);
    const setpoint = response.DEV_AUT_CODE.startsWith('DAM') && !response.ECONOMY_ENABLED ? response.SETPOINT_EXCEPTION_ECO : response.SETPOINT;
    setCurrentSetpoint(setpoint);
    setCheckAutomaticMode(response.ECONOMY_ENABLED);
    setAutomationIntervals(response.AUTOMATIONS_INTERVALS.map((interval) => ({
      endTime: interval.END,
      startTime: interval.START,
    })));

    setLoading(false);
  }

  async function getDutIrSetpoints(): Promise<void> {
    if (!machineInfo) return;
    if (!machineInfo.deviceCode.startsWith('DUT')) return;

    const response = await getIrCommandsList(machineInfo.deviceCode);
    const irCommands = identifyDutIrCommands(response.list)
      .filter((command) => {
        const cmdSetpoint = Number(command?.cmdName?.split(':')[1]) || null;
        return !!cmdSetpoint;
      });
    const setpoints: { id: string, setpoint: number}[] = irCommands.map((command) => ({
      id: command.IR_ID as string,
      setpoint: Number(command?.cmdName?.split(':')[1]),
    })).sort((a, b) => a.setpoint - b.setpoint);

    setAvailableSetpoints(setpoints);
  }

  function handleBeforeUnload(event: BeforeUnloadEvent): void {
    if (hasEditedValuesRef.current) {
      event.preventDefault();
    }
  }

  const currentTemperatureComponent = useMemo((): ReactElement => (environments.length > 0 ? (
    <CurrentTemperatureEnvironmentContainer>
      <h2>{t('temperaturaAtual')}</h2>
      <ul>
        {environments.map((env) => (
          <EnvironmentTemperatureItem
            environmentName={env.name}
            deviceCode={env.dutCode}
            key={`${env.name}-${env.dutCode}`}
          />
        ))}
      </ul>
    </CurrentTemperatureEnvironmentContainer>
  ) : <></>), [environments]);

  const hasEditedValues = useCallback((): boolean => {
    if (!defaultValues) return false;
    return defaultValues.ECONOMY_ENABLED !== checkAutomaticMode
    || defaultValues.OPERATION !== currentOperation
    || defaultValues.SETPOINT !== currentSetpoint;
  }, [checkAutomaticMode, currentOperation, currentSetpoint, defaultValues]);

  const availableSetpointsValues = useMemo(() => availableSetpoints.map(({ setpoint }) => setpoint), [availableSetpoints]);

  const blockedOperation = useMemo(() => defaultValues?.OPERATION === 'blocked', [defaultValues]);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    let needMachineInfo = true;
    if (history.location.state) {
      if (typeof history.location.state === 'object'
        && 'MACHINE_NAME' in history.location.state
        && 'MACHINE_TYPE' in history.location.state
        && 'deviceCode' in history.location.state) {
        needMachineInfo = false;
        setMachineInfo({
          name: history.location.state.MACHINE_NAME,
          type: history.location.state.MACHINE_TYPE,
          deviceCode: history.location.state.deviceCode,
        });
      }
    }

    if (machineId) {
      getMachine(needMachineInfo);
    }

    return () => {
      history.location.state = {};
    };
  }, []);

  useEffect(() => {
    if (machineInfo && !firstRender.current) {
      firstRender.current = true;
      getDutIrSetpoints();
    }
  }, [machineInfo]);

  return (
    <>
      <SaveChangesModal
        open={openSaveChangesModal}
        onClose={onCloseSaveChangesModal}
        onClickSave={onClickSaveModal}
        disabled={loading}
      />
      <ContainerPage>
        {loading && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        <HeaderMachine
          name={machineInfo?.name ?? ''}
          deviceCode={machineInfo?.deviceCode ?? ''}
          type={machineInfo?.type ?? ''}
          hasEditedValues={hasEditedValues}
        />
        <AutomaticMachineActivate
          checked={checkAutomaticMode}
          onClickCheck={onClickCheckAutomaticMode}
        />
        <SetpointContainer>
          {currentOperation === 'blocked' && checkAutomaticMode ? (
            <BlockedMessageContent />
          ) : (
            <>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h1
                  {...(!isDesktop && !checkAutomaticMode && { style: { marginBottom: '15px' } })}
                >
                  {t('temperaturaDesejada')}
                </h1>
                {!isDesktop && checkAutomaticMode && (
                <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                  <InfoIconCR />
                  <p style={{
                    margin: 0, fontSize: '10px', color: colors.Grey, lineHeight: '14px',
                  }}
                  >
                    {t('desligueModoAutomaticoAlterarTemperatura')}
                  </p>
                </div>
                )}
              </div>
              <div>
                <SetpointController
                  currentSetpoint={currentSetpoint}
                  power={currentOperation ? currentOperation !== 'disabled' : undefined}
                  limits={limits ?? {
                    tMin: 20,
                    tMax: 25,
                  }}
                  disabled={loading || checkAutomaticMode}
                  availableSetpoints={availableSetpointsValues}
                  onChangeSetpoint={onChangeSetpoint}
                  onClickPower={onClickPower}
                  disabledSetpoint={!!blockedOperation && !checkAutomaticMode || currentOperation === 'fan'}
                />
              </div>
              <RecommendedTemperatureContainer>
                <h2>{t('temperaturaRecomendada')}</h2>
                <LimitTemperatureContainer>
                  <p>{`${t('min')} ${limits?.tMin ?? '-'}°C`}</p>
                  <p>{`${t('max')} ${limits?.tMax ?? '-'}°C`}</p>
                </LimitTemperatureContainer>
              </RecommendedTemperatureContainer>

              {!isDesktop && currentTemperatureComponent}

              {currentOperation && (
              <ModeMachineContainer>
                <h2>{t('modalidade')}</h2>
                <div>
                  <ButtonOperation
                    currentOperation={currentOperation}
                    disabled={loading || checkAutomaticMode || currentOperation === 'disabled' || blockedOperation}
                    onClickModality={() => onClickModality('cool')}
                    operation="cool"
                  />
                  <ButtonOperation
                    currentOperation={currentOperation}
                    disabled={loading || checkAutomaticMode || currentOperation === 'disabled' || blockedOperation}
                    onClickModality={() => onClickModality('fan')}
                    operation="fan"
                  />
                </div>
              </ModeMachineContainer>
              )}
            </>
          )}

          <ScheduleHoursContainer>
            <ScheduleHoursTitle>
              <h2>{t('horarioDeFuncionamento')}</h2>
              <div>
                <CalendarSimpleIcon color={colors.Grey} />
                <p>{renderFormattedDate()}</p>
              </div>
            </ScheduleHoursTitle>

            <ScheduleBadgesContainer>
              {automationIntervals.map((interval) => (
                <ScheduleBadge
                  beginTime={interval.startTime}
                  endTime={interval.endTime}
                  key={`${interval.startTime}-${interval.endTime}`}
                />
              ))}
            </ScheduleBadgesContainer>
          </ScheduleHoursContainer>

        </SetpointContainer>
        {isDesktop && currentTemperatureComponent}
        <EconomySaveInfoContainer>
          {isDesktop && (
            <EconomySaveIcon />
          )}
          <p>{t('antesAjustesConsumoConfortoEconomia')}</p>
          {!isDesktop && (
            <EconomySaveIcon />
          )}
        </EconomySaveInfoContainer>
        <Button
          onClick={onClickSave}
          variant="rc-green"
          style={{
            width: isDesktop ? '100%' : 'auto',
            margin: !isDesktop ? '0 24px' : undefined,
          }}
          disabled={loading || !hasEditedValues()}
        >
          {t('salvarAlteracoes')}
        </Button>
      </ContainerPage>
    </>
  );
}
