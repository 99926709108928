/**
 * Calcula o campo `stroke-dasharray` do SVG de progresso (azul) do
 * controle de setpoint.
 *
 * @param {number | undefined} setpoint - Setpoint atual
 * @param {{ tMin: number, tMax: number}} limits - Limites de setpoint
 * @param {number[]} [availableSetpoints] - Array de setpoints possíveis
 * @return {*}  {{ setpointStrokeDashArray: string }}
 */
export function useCalculateStrokeSetpoint(
  setpoint: number | undefined,
  limits: { tMin: number, tMax: number},
  availableSetpoints?: number[],
): { setpointStrokeDashArray: string } {
  const totalDashes = 502.4; // Total de pontos do círculo
  const maxDashes = 401.63; // Total de pontos disponíveis
  const minimumValue = 5.4; // Minimo valor de pontos para o setpoint mais baixo

  function generateSetpointStrokeValue(): string {
    let setpointPositionPercent: number;

    if (!setpoint) return `${minimumValue} ${totalDashes - minimumValue}`;

    if (!availableSetpoints || availableSetpoints.length === 0) {
      setpointPositionPercent = ((setpoint - limits.tMin) / (limits.tMax - limits.tMin)) * 100;
    } else {
      const foundIndexSetpoint = availableSetpoints.findIndex((item) => item === setpoint);

      if (foundIndexSetpoint < 0) {
        throw new Error('Error calculating setpoint');
      }

      setpointPositionPercent = (foundIndexSetpoint / (availableSetpoints.length - 1)) * 100;
    }

    if (setpointPositionPercent === undefined) {
      throw new Error('Error calculating setpoint');
    }

    const dashesPosition = ((maxDashes * setpointPositionPercent) / 100).toFixed(2);
    const newDashPosition = Number(dashesPosition) === 0 ? minimumValue : Number(dashesPosition);

    return `${newDashPosition} ${totalDashes - newDashPosition}`;
  }

  return {
    setpointStrokeDashArray: generateSetpointStrokeValue(),
  };
}
