import styled from 'styled-components';
import { colors } from 'styles/colors';
import { colors as remoteColors } from '~/styles/remoteControlColors';

export const ContainerHeader = styled.div`
  width: 100%;
  background: ${remoteColors.BlueGreenGradient};
  border-radius: 8px;
  height: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  padding-left: 40px;

  @media (max-width: 768px) {
    height: 220px;
    padding: 24px;
    align-items: center;
    position: relative;
    border-radius: 0;

    h1, p {
      text-align: center;
    }
  }
`;

export const ContainerPage = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;

  p, h1, h2, h3 {
    margin: 0;
  }

  @media (max-width: 768px) {
    padding: 0 0 24px 0;
  }
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 24px;
  color: ${remoteColors.White};
  line-clamp: 3;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

export const Subtitle = styled.p`
  margin: 0;
  font-size: 10px;
  color: ${remoteColors.White};

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  line-clamp: 1;
  text-overflow: ellipsis;
`;

export const ContainerAutomaticMachine = styled.div`
  width: 100%;
  background-color: ${remoteColors.White};
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  gap: 20px;

  p {
    font-weight: 400;
    font-size: 12px;
    color: ${remoteColors.Grey};
  }

  h2 {
    font-size: 18px;
    font-weight: 600;
    color: ${remoteColors.Black};
  }

  @media (max-width: 768px) {
    padding: 16px;
    margin: 0 24px;
    width: auto;

    h2 {
      font-size: 16px;
    }
  }
`;

export const Label = styled.span`
  transition: all 0.2s;
  margin-top: -6px;
  margin-left: 16px;
  margin-right: 16px;
  color: ${colors.Blue700};
  font-size: 11px;
  font-weight: bold;
`;

export const Link = styled.div`
  font-size: 11px;
  line-height: 13px;
  color: #363BC4;
  text-decoration: underline;
  margin-top: 6px;
  cursor: pointer;
`;

export const WifiIconContainer = styled.div`
  background-color: ${remoteColors.Blue02Opacity};
  border-radius: 6px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 1px;
  }

  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    width: 24px;
    height: 24px;
    border-radius: 5px;
  }
`;

export const ConnectionContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${remoteColors.White};

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    vertical-align: center;
  }

  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    gap: 6px;

    p {
      font-size: 14px;
      line-height: 16px;
    }
  }
`;

export const SetpointContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background-color: ${remoteColors.White};
  border-radius: 12px;
  gap: 40px;
  padding: 40px;
  width: 100%;

  h1, h2 {
    color: ${remoteColors.Blue02};
    font-size: 24px;
    font-weight: 500;
  }

  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    background-color: transparent;
    margin: 8px 24px 0;
    padding: 0;
    width: auto;

    h1, h2 {
      font-size: 16px;
    }
  }
`;

export const CurrentTemperatureEnvironmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${remoteColors.White};
  border-radius: 12px;
  padding: 24px;
  gap: 40px;

  h2 {
    font-size: 24px;
    font-weight: 500;
    color: ${remoteColors.Blue02};
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  li {
    padding: 16px;
    border-bottom: 1px solid ${remoteColors.Grey03};
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: ${remoteColors.Blue02};
  }

  span {
    font-size: 10px;
    font-weight: 500;
    color: ${remoteColors.Grey};
    line-height: 12px;
  }

  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    background-color: transparent;
    padding: 0;
    align-items: center;
    width: 100%;

    li {
      padding: 0;
    }

    h2 {
      font-size: 16px;
    }

    p {
      font-size: 12px;
    }
  }
`;

export const EconomySaveInfoContainer = styled.div`
  display: flex;
  background-color: ${remoteColors.White};
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 24px;
  border-radius: 12px;

  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: ${remoteColors.Grey};
    width: 440px;
  }

  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    position: relative;
    justify-content: flex-start;
    gap: 0;
    margin: 24px 24px 0;
    padding: 22px 24px;

    p {
      font-size: 16px;
      line-height: 16px;
      width: 225px;
    }

    svg {
      position: absolute;
      top: -2px;
      right: 8%;
      scale: 1.4;

    }
  }
`;

export const LimitTemperatureContainer = styled.div`
  display: flex;
  gap: 8px;

  p {
    font-size: 18px;
    font-weight: 500;
    color: ${remoteColors.Grey};
  }

  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    p {
      font-size: 14px;
    }
  }
`;

export const ScheduleBadgeContainer = styled.div`
  height: 25px;
  min-width: 130px;
  padding: 4px 8px;
  border-radius: 1000px;
  background-color: ${remoteColors.Green};
  display: flex;
  gap: 4px;

  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    font-variant-numeric: tabular-nums;
  }
`;

export const ScheduleHoursTitle = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    gap: 8px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    color: ${remoteColors.Grey};
    text-transform: capitalize;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    gap: 20px;

    p {
      font-size: 12px;
    }

    div {
      gap: 4px;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const RoundedButton = styled.button`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 9999px;

  &:disabled {
    opacity: 25%;
  }
`;

export const SetpointButton = styled(RoundedButton)`
  border: 1px solid ${remoteColors.Grey04};
  background-color: ${remoteColors.White};

`;

export const PowerButton = styled(RoundedButton)<{off: boolean}>`
  background-color: ${({ off }) => (!off ? remoteColors.Grey : remoteColors.Green02)};
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &: disabled {
    background-color: ${remoteColors.Grey};
  }
`;

export const SetpointLabel = styled.p<{ color: string }>`
  width: 118px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-variant-numeric: tabular-nums;
  font-weight: 400;
  font-size: 48px;
  color: ${({ color }) => color};
  text-align: center;
`;

export const SetpointControllerContent = styled.div`
  height: 208px;
  width: 224px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const IconButton = styled.button`
  margin: 0 0 0 auto;
  padding: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const ModeMachineContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;

  div {
    display: flex;
    gap: 16px;
  }

  button {
    width: 256px;
  }

  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    background-color: ${remoteColors.White};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    padding: 16px;

    button {
      height: 55px;
    }

    div {
      width: 100%;
    }
  }
`;

export const ScheduleBadgesContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    align-items: flex-start;
  }
`;

export const ScheduleHoursContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;

  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    gap: 24px;
  }
`;

export const RecommendedTemperatureContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    gap: 12px;
  }
`;

export const LoaderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  background-color: rgba(239, 241, 244, 0.7);
`;

export const BlockedMessageContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  p {
    font-weight: 500;
    font-size: 16px;
    color: ${remoteColors.Grey};
  }

  div {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    max-width: 600px;
  }

  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    p {
      text-align: justify;
      font-size: 10px;

    }

    div {
      margin: 0 24px;
      max-width: none;
    }

    svg {
      flex-shrink: 0;
    }
  }
`;

export const FooterContainerModal = styled.div`
  display: flex;
  gap: 16px;
  margin-left: auto;

  button {
    height: 54px;
    width: 160px;
  }

  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    margin: 0;
    flex-direction: column;

    button {
      width: auto;
    }
  }

`;

export const LeavePageModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  padding: 24px;
`;
