export const SplitPisoTetoIcon = ({ width, height, color }: { width?: number, height?: number, color?: string }): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? 18}
    height={height ?? 14}
    fill="none"
    viewBox="0 0 18 14"
  >
    <path
      stroke={color ?? '#363BC4'}
      d="M1.259 9.434A1.5 1.5 0 0 1 2.695 7.5h12.61a1.5 1.5 0 0 1 1.436 1.934l-.907 3a1.5 1.5 0 0 1-1.436 1.066H3.602a1.5 1.5 0 0 1-1.436-1.066z"
    />
    <rect width="13" height="2" x="2.5" y="7.5" stroke={color ?? '#363BC4'} rx="1" />
    <path
      stroke={color ?? '#363BC4'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4 12h3M5.559 8l-.265 1.3M7.941 8l-.264 1.3M10.323 8l-.264 1.3M12.706 8l-.265 1.3M11 12h3M14.385 2.154h-.116a1.616 1.616 0 0 0-1.615 1.615v1.27M4 2.153h.116A1.615 1.615 0 0 1 5.73 3.769v1.27M9.192 1v4.038"
    />
  </svg>
);
