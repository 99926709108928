import { ReactElement, useState } from 'react';
import { useWebSocket } from 'helpers/wsConnection';
import { Thermometer } from 'icons';

interface EnvironmentTemperatureItemProps {
  readonly environmentName: string;
  readonly deviceCode: string;
}

export function EnvironmentTemperatureItem({
  environmentName,
  deviceCode,
}: EnvironmentTemperatureItemProps): ReactElement {
  const [temperature, setTemperature] = useState<number>();
  const [status, setStatus] = useState<'OFFLINE' | 'ONLINE' | 'LATE'>();

  function onWsOpen(wsConn): void {
    wsConn.send({ type: 'dutSubscribeRealTime', data: { DUT_ID: deviceCode } });
  }

  function onWsMessage(payload): void {
    if (payload.type === 'dutTelemetry' && payload.data.dev_id === deviceCode) {
      setTemperature(payload.data.Temperature);
      setStatus(payload.data.status);
    }
  }

  function beforeWsClose(wsConn): void {
    wsConn.send({ type: 'dutUnsubscribeRealTime' });
  }

  function renderBackgroundStatusColor() : string {
    switch (status) {
      case 'ONLINE': return '#5AB365';
      case 'OFFLINE': return '#E00030';
      default: return '#2D81FF';
    }
  }

  useWebSocket(onWsOpen, onWsMessage, beforeWsClose);

  return (
    <li>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        <div style={{
          width: 5,
          height: 5,
          borderRadius: 99999,
          backgroundColor: renderBackgroundStatusColor(),
          marginLeft: '4px',
        }}
        />
        <p>{environmentName}</p>
        <div style={{
          marginLeft: 'auto', display: 'flex', gap: '8px', justifySelf: 'self-end',
        }}
        >
          <Thermometer />
          <span>{`Temp ${temperature ?? '-'}°C`}</span>
        </div>
      </div>
    </li>
  );
}
