export const colors = {
  Black: '#000000',
  Blue: '#363BC4',
  Blue02: '#0F0F36',
  Blue02Opacity: '#0F0F3633',
  BlueGreenGradient: 'linear-gradient(107.22deg, #363BC4 29.9%, #00FF9B 123.46%)',
  Green: '#00FF9B',
  Green02: '#00D698',
  Grey: '#9C9C9C',
  Grey02: '#EFF1F4',
  Grey03: '#E1E1E1',
  Grey04: '#CAD3E0',
  White: '#FFFFFF',
};
