import { Route, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import MenuContext from '~/contexts/menuContext';
import { DesktopNavbar } from '~/components/DesktopNavbar';
import { MobileNavbar } from '~/components/MobileNavbar';
import { useContext } from 'react';
import { apmElastic } from 'helpers/apmElastic';
import { useAuth } from 'react-oidc-context';
import { getUserProfile } from '~/helpers/userProfile';

const PrivateRouteWrapper = styled.div<{ isMenuOpen, isRemoteControl }>(
  ({ isMenuOpen, isRemoteControl }) => `
  padding: ${isRemoteControl ? '0px' : '24px 16px'};
  @media (min-width: 768px) {
    padding-top: 70px;
    padding-left: ${isMenuOpen ? '270px' : '75px'};
    padding-right: 20px;
  }
`,
);

type PrivateRouteProps = {
  component: React.ElementType;
  path?: string;
}

export const PrivateRoute = ({ component: Component, ...params }: PrivateRouteProps): JSX.Element => {
  const { isAuthenticated } = useAuth();
  const { menuToogle } = useContext(MenuContext);
  const isRemoteControl = !!params.path?.includes('/controle-remoto');

  return (
    <>
      <DesktopNavbar />
      <MobileNavbar />
      <PrivateRouteWrapper isMenuOpen={menuToogle} isRemoteControl={isRemoteControl}>
        <Route
          {...params}
          render={(props) => {
            if (process.env.REACT_APP_APM_ELASTIC) {
              apmElastic(props.location.pathname);
            }
            return isAuthenticated ? <Component {...props} /> : <Redirect to="/logout" />;
          }}
        />
      </PrivateRouteWrapper>
    </>
  );
};
