import { useCallback, useState } from 'react';
import {
  DriError, DriItemList, DriOffline, ValidateSchedsOptions,
} from '../types';
import { DriException, DriSchedule } from 'pages/Analysis/SchedulesModals/DRI_ScheduleModal';
import { useDriProgrammingContext } from '../context/ProgrammingsContext';
import { apiCall, ApiParams } from '~/providers';
import {
  LIMIT_DRIS_SENDING_SCHEDS, MAXIMUM_EXCEPTIONS, MAXIMUM_SCHEDULES, splitProgrammingToSchedulesExceptions,
  verifyErrorsRequestProgramming,
} from '../constants';
import { t } from 'i18next';
import { batchRequests } from '~/helpers/batchRequests';
import { formatStringToDate } from '../../../../../../helpers/dates';

interface UseSaveDriSchedulesResponse {
  loading: boolean;
  progressState: { currentDevices: number; totalDevices: number};
  sendSchedules: (selectedDevices: DriItemList[], newScheds: { schedules: DriSchedule[], exceptions: DriException[] }, conflictsScheds: ValidateSchedsOptions) => Promise<{ drisErrors: DriError[], drisOffline: DriOffline[]}>;
}

export function useSaveDriSchedules(): UseSaveDriSchedulesResponse {
  const [loading, setLoading] = useState(false);
  const [progressState, setProgressState] = useState({
    currentDevices: 0,
    totalDevices: 0,
  });
  const { scheds } = useDriProgrammingContext();

  const prepareSendingSchedsByDevice = useCallback((
    deviceId: string,
    newScheds: { schedules: DriSchedule[], exceptions: DriException[] },
    conflictsScheds: ValidateSchedsOptions,
  ): ApiParams['/dri/handle-multiple-sched'] => {
    const response: ApiParams['/dri/handle-multiple-sched'] = {
      DRI_ID: deviceId,
      EXCEPTIONS: newScheds.exceptions.map((except) => ({
        ACTIVE: except.ACTIVE,
        BEGIN_TIME: except.BEGIN_TIME,
        END_TIME: except.END_TIME,
        EXCEPTION_DATE: formatStringToDate(except.EXCEPTION_DATE) as string,
        EXCEPTION_REPEAT_YEARLY: except.EXCEPTION_REPEAT_YEARLY,
        NAME: except.NAME,
        OPERATION: except.OPERATION,
        MODE: except.MODE,
        SETPOINT: except.SETPOINT,
        DELETE: false,
        EDIT: false,
        INSERT: true,
      })),
      SCHEDS: newScheds.schedules.map((sched) => ({
        ACTIVE: sched.ACTIVE,
        BEGIN_TIME: sched.BEGIN_TIME,
        DAYS: sched.DAYS,
        END_TIME: sched.END_TIME,
        NAME: sched.NAME,
        OPERATION: sched.OPERATION,
        MODE: sched.MODE,
        SETPOINT: sched.SETPOINT,
        DELETE: false,
        EDIT: false,
        INSERT: true,
      })),
    };

    const { exceptionsToDelete, schedulesToDelete, schedulesToEdit } = conflictsScheds;
    const currentScheds = scheds[deviceId];
    const { exceptions, schedules } = splitProgrammingToSchedulesExceptions(currentScheds);

    for (const except of exceptions) {
      response.EXCEPTIONS.push({
        ACTIVE: except.ACTIVE,
        BEGIN_TIME: except.BEGIN_TIME,
        END_TIME: except.END_TIME,
        EXCEPTION_DATE: except.EXCEPTION_DATE,
        EXCEPTION_REPEAT_YEARLY: except.EXCEPTION_REPEAT_YEARLY,
        NAME: except.NAME,
        OPERATION: except.OPERATION,
        MODE: except.MODE,
        SETPOINT: except.SETPOINT,
        SCHED_ID: except.SCHED_ID,
        DELETE: exceptionsToDelete.includes(except.SCHED_ID),
        EDIT: false,
        INSERT: false,
      });
    }

    for (const sched of schedules) {
      const schedHasEdit = schedulesToEdit.find(({ id }) => sched.SCHED_ID === id);
      response.SCHEDS.push({
        ACTIVE: sched.ACTIVE,
        BEGIN_TIME: sched.BEGIN_TIME,
        DAYS: schedHasEdit ? schedHasEdit.days : sched.DAYS,
        END_TIME: sched.END_TIME,
        NAME: sched.NAME,
        OPERATION: sched.OPERATION,
        MODE: sched.MODE,
        SETPOINT: sched.SETPOINT,
        SCHED_ID: sched.SCHED_ID,
        DELETE: schedulesToDelete.includes(sched.SCHED_ID),
        EDIT: !!schedHasEdit,
        INSERT: false,
      });
    }

    return response;
  }, [scheds]);

  function verifyLimitsSchedules(scheds: ApiParams['/dri/handle-multiple-sched']['SCHEDS']) : boolean {
    const filteredEditNewScheds = scheds.filter((item) => !!item.EDIT || !!item.INSERT);

    return filteredEditNewScheds.length <= MAXIMUM_SCHEDULES;
  }

  function verifyLimitsExceptions(exceptions: ApiParams['/dri/handle-multiple-sched']['EXCEPTIONS']): boolean {
    const filteredEditNewExceptions = exceptions.filter((item) => !!item.EDIT || !!item.INSERT);

    return filteredEditNewExceptions.length <= MAXIMUM_EXCEPTIONS;
  }

  function onCompleteProgressDevice(): void {
    setProgressState((previousState) => ({
      ...previousState,
      currentDevices: previousState.currentDevices + 1,
    }));
  }

  const sendSchedsByDevice = useCallback(async (
    device: DriItemList,
    newScheds: { schedules: DriSchedule[], exceptions: DriException[] },
    conflictsScheds: ValidateSchedsOptions,
    drisErrors: DriError[],
  ): Promise<void> => {
    const preparedData = prepareSendingSchedsByDevice(device.DRI_ID, newScheds, conflictsScheds);

    if (!verifyLimitsSchedules(preparedData.SCHEDS)) {
      drisErrors.push({
        DRI_ID: device.DRI_ID,
        message: t('driExcedeuLimiteProgramacoes', { devId: device.DRI_ID }),
        UNIT_ID: device.UNIT_ID,
      });
      return;
    }

    if (!verifyLimitsExceptions(preparedData.EXCEPTIONS)) {
      drisErrors.push({
        DRI_ID: device.DRI_ID,
        message: t('driExcedeuLimiteExcecoes', { devId: device.DRI_ID }),
        UNIT_ID: device.UNIT_ID,
      });
      return;
    }

    await apiCall('/dri/handle-multiple-sched', preparedData);
  }, [prepareSendingSchedsByDevice]);

  function buildSendSchedulePromise(
    device: DriItemList,
    newScheds: { schedules: DriSchedule[], exceptions: DriException[] },
    conflictsScheds: ValidateSchedsOptions,
    drisErrors: DriError[],
    drisOffline: DriOffline[],
  ) : () => Promise<void> {
    return () => sendSchedsByDevice(device, newScheds, conflictsScheds, drisErrors)
      .catch((error) => {
        verifyErrorsRequestProgramming(error, drisErrors, drisOffline, { DRI_ID: device.DRI_ID, UNIT_ID: device.UNIT_ID });
      })
      .finally(() => onCompleteProgressDevice());
  }

  const sendSchedules = useCallback(async (
    selectedDevices: DriItemList[],
    newScheds: { schedules: DriSchedule[], exceptions: DriException[] },
    conflictsScheds: ValidateSchedsOptions,
  ): Promise<{
    drisErrors: DriError[],
    drisOffline: DriOffline[],
  }> => {
    setLoading(true);
    const drisErrors: DriError[] = [];
    const drisOffline: DriOffline[] = [];
    setProgressState({
      currentDevices: 0,
      totalDevices: selectedDevices.length,
    });

    const promises = selectedDevices.map((device) => buildSendSchedulePromise(device, newScheds, conflictsScheds, drisErrors, drisOffline));

    await batchRequests(promises, LIMIT_DRIS_SENDING_SCHEDS);

    setLoading(false);

    return {
      drisErrors,
      drisOffline,
    };
  }, [scheds, sendSchedsByDevice]);

  return {
    loading,
    progressState,
    sendSchedules,
  };
}
