type PowerRCIconProps = {
  color?: string;
  size?: string;
}

export const PowerRCIcon = ({ color = '#FFFFFF', size }: PowerRCIconProps): JSX.Element => (
  <svg width={size ?? '19'} height={size ?? '20'} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.0883 2.70004C13.5896 2.40004 12.9911 2.60004 12.6919 3.10004C12.3927 3.60004 12.5922 4.20004 13.0909 4.50004C15.1855 5.80004 16.5819 8.10004 16.5819 10.5C16.5819 14.4 13.4899 17.5 9.59986 17.5C5.70986 17.5 2.61781 14.4 2.61781 10.5C2.61781 8.00004 4.01422 5.70004 6.10883 4.40004C6.50781 4.10004 6.7073 3.50004 6.40806 3.10004C6.10883 2.60004 5.51037 2.40004 5.01165 2.70004C2.21883 4.30004 0.523193 7.30004 0.523193 10.5C0.523193 15.5 4.51294 19.5 9.50012 19.5C14.4873 19.5 18.477 15.5 18.477 10.5C18.477 7.30004 16.7814 4.40004 14.0883 2.70004Z" fill={color} />
    <path d="M9.50012 11.5C10.0986 11.5 10.4976 11.1 10.4976 10.5V1.5C10.4976 0.9 10.0986 0.5 9.50012 0.5C8.90166 0.5 8.50269 0.9 8.50269 1.5V10.5C8.50269 11.1 8.90166 11.5 9.50012 11.5Z" fill={color} />
  </svg>
);
