import { ApiParams, ApiResps, apiCall } from '~/providers';

export async function getMachineInfo(reqParams: ApiParams['/remote-control/get-machine-info']): Promise<ApiResps['/remote-control/get-machine-info'] | undefined> {
  try {
    return await apiCall('/remote-control/get-machine-info', reqParams);
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

export async function setMachineConfig(reqParams: ApiParams['/remote-control/set-config']): Promise<ApiResps['/remote-control/set-config'] | undefined> {
  try {
    return await apiCall('/remote-control/set-config', reqParams);
  } catch (error) {
    console.log(error);
    return undefined;
  }
}

export async function getIrCommandsList(devId: string): Promise<ApiResps['/get-dut-ircodes-list']> {
  try {
    return await apiCall('/get-dut-ircodes-list', { devId });
  } catch (error) {
    console.log(error);
    return { list: [], dutCommandTypes: [] };
  }
}
