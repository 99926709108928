import styled, { keyframes } from 'styled-components';
import DefaultImage from '../../../assets/img/DefaultImageUnitControlRemote.png';
import { colors } from '~/styles/colors';

const blinkAnimation = keyframes`
  0%, 100% {
    opacity: 1; // Totalmente visível
  }
  50% {
    opacity: 0; // Invisível
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  background-color: red;
  border-radius: 8px;
  height: 200px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    border-radius: 0px;
    margin: 0px;
  }
`;

export const ImageHeader = styled.div`
  width: 100%;
  height: 200px;
  background-image: linear-gradient(rgba(15, 15, 54, 0.90), rgba(15, 15, 54, 0.78), rgba(15, 15, 54, 0.51)), url(${DefaultImage});
  background-size: cover;
  background-position: center;
`;

export const LineStyle = styled.div`
  width: 0%;
  height: 0px;
  background-color: rgba(239, 241, 244, 1);
  @media (max-width: 768px) {
    width: 100%;
    height: 15px;
    background-color: rgba(239, 241, 244, 1);
    border-radius: 17px 17px 0px 0px;
    position: absolute;
    bottom: 0px;
  }
`;

export const ContainerPage = styled.div<{isMobile}>`
  padding: ${(props) => (props.isMobile ? '0px' : '0px 15px')};
`;

export const TextOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%); /* Centraliza o texto horizontal e verticalmente */
  color: white;
  text-align: center; /* Centraliza o texto horizontalmente */
  z-index: 2; /* Garante que o texto fique acima da imagem */
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 32px;
  color: rgba(255, 255, 255, 1);
`;

export const Subtitle = styled.p`
  margin: 0;
  font-size: 16px;
  color: rgba(0, 255, 155, 1);
`;

export const ContainerBoxMachines = styled.div<{ isMobile, haveData }>`
  width: 100%;
  height: auto;
  background: ${(props) => (props.isMobile ? 'transparent' : 'rgba(255, 255, 255, 1)')};
  padding: ${(props) => (props.isMobile ? '0px 30px' : '20px')};
  border-radius: 15px;
  display: ${(props) => (props.haveData ? 'grid' : 'flex')};
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    grid-template-columns: auto;
  }

  @media (min-width: 769px) and (max-width: 1024px) {
    /* Para tablets */
    grid-template-columns: 1fr;
  }

  @media (min-width: 2560px) {
    /* Para telas muito largas */
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const ContainerCardMachine = styled.div`
  width: 100%;
  border: 1px solid rgba(218, 218, 218, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 12px;
  padding: 10px 15px;
  height: 100%;
  min-height: 180px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    /* Para dispositivos móveis */
    max-width: 100%;
  }

`;

export const ArrowStyle = styled.div`
`;

export const InfoMachineSubtitles = styled.div`
  font-size: 12px;
  color: rgba(156, 156, 156, 1);
  gap: 5px;
  margin-top: 5px;
  h3 {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
    line-height: 1.2;
  }
`;

export const ContainerSubTitles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  p {
    margin: 0;
  }
`;

export const ContainerSemMaquinas = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 200px;
  position: relative;
  flex-grow: 1;
  span {
    margin: 0;
    color: rgba(15, 15, 54, 1);
  }
`;

export const BoxIconInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  div {
    display: flex;
    align-items: center;
  }
`;

export const ContainerColorEnv = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
`;

export const IconColorEnv = styled.div`
  gap: 5px;
  width: 4px;
  height: 4px;
  border-radius: 10px;
  animation: ${blinkAnimation} 2s infinite;
`;

export const TitleMachine = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  h3 {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
    line-height: 1.2;
    margin: 0;
    color: rgba(15, 15, 54, 1);
    font-weight: 600;
  }
`;

export const ContainerAutomaticMachines = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  gap: 20px;
`;

export const LineSeparator = styled.div`
  border-top: 1px solid rgba(202, 211, 224, 1);
  height: 1px;
  width: 100%;
  margin-top: 5px;
`;

export const ThermometherArea = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;
  p {
    font-size: 10px;
  }
`;

export const SearchInput = styled.div`
  min-height: 48px;
  margin: 0;
  font-size: 12px;
  color: #000;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box !important;
  display: inline-flex;
  background: rgba(255, 255, 255, 1);
  input {
    box-shadow: unset;
  }
`;

export const SearchInputMobile = styled.div`
  min-height: 48px;
  margin: 0;
  font-size: 12px;
  color: #000;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  margin: 10px 0px;
  margin-bottom: 30px;
  input {
    background: rgba(255, 255, 255, 1);
    width: 100%;
    box-shadow: unset;
    border-radius: 16px;
  }
  div:nth-child(1) {
    width: 100%;
  }
  svg {
    fill: rgba(54, 59, 196, 1);
  }
`;

export const Label = styled.span`
  transition: all 0.2s;
  margin-top: -8px;
  margin-left: 16px;
  margin-right: 16px;
  color: ${colors.Blue700};
  font-size: 11px;
  font-weight: bold;
`;

export const Clean = styled.div`
  font-size: 11px;
  line-height: 13px;
  color: #363BC4;
  text-decoration: underline;

  margin-top: 6px;

  cursor: pointer;
`;

export const AutomaticModeTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  h2 {
    margin: 0;
    font-size: 18px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
  }
  p {
    color: rgba(156, 156, 156, 1);
    font-size: 12px;
    font-weight: 400;
    margin: 0px;
  }
`;
