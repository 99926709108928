export interface VersionNumber {
  vMajor: number;
  vMinor: number;
  vPatch: number;
  vExtra?: string;
}

export function parseFwVersion(str: string): VersionNumber | undefined {
  if (!str) return undefined;
  str = str.trim();
  const lastBar = str.lastIndexOf('/');
  const folder = (lastBar >= 0) ? str.substring(0, lastBar + 1) : null;

  if (folder) str = str.substring(folder.length);

  const isDotBin = str.toLowerCase().endsWith('.bin');

  if (isDotBin) str = str.substring(0, str.length - 4);

  const match = str.match(/^v?(\d+)\.(\d+)\.(\d+)(-[-a-zA-Z0-9]+)?$/) || str.match(/^(\d+)_(\d+)_(\d+)$/);

  if (!match) return undefined;
  const [, vMajor, vMinor, vPatch, vExtra] = match;
  return {
    vMajor: Number(vMajor),
    vMinor: Number(vMinor),
    vPatch: Number(vPatch),
    vExtra,
  };
}

export const isFwVersionGreatestOrEqual = (referenceVersion: string, devFwVersion: string): boolean => {
  if (!referenceVersion && !devFwVersion) return false;

  const devVersion = parseFwVersion(devFwVersion);
  const refVersion = parseFwVersion(referenceVersion);

  if (!devVersion || !refVersion) return false;

  if (devVersion.vMajor > refVersion.vMajor) return true;
  if (devVersion.vMajor < refVersion.vMajor) return false;

  if (devVersion.vMinor > refVersion.vMinor) return true;
  if (devVersion.vMinor < refVersion.vMinor) return false;

  if (devVersion.vPatch > refVersion.vPatch) return true;
  if (devVersion.vPatch < refVersion.vPatch) return false;

  return true; // Todas partes são iguais
};
