import React, { Suspense } from 'react';
import { KcPage } from './keycloak-theme/kc.gen';
import { createRoot } from 'react-dom/client';
import AppEntrypoint from './index.app';

// descomentar esta linha para testar as páginas do keycloak
// eslint-disable-next-line import/newline-after-import
// import { getKcContextMock } from './keycloak-theme/login/KcPageStory';
// window.kcContext = getKcContextMock({
//   pageId: 'error.ftl',
//   overrides: {},
// });

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {window.kcContext ? (
      <KcPage kcContext={window.kcContext} />
    ) : (
      <Suspense>
        <AppEntrypoint />
      </Suspense>
    )}
  </React.StrictMode>,
);
