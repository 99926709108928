type BackIconProps = {
  color?: string;
  size?: string;
}

export const BackRCIcon = ({
  color = '#FFFFFF',
  size,
}: BackIconProps): JSX.Element => (
  <svg width={size ?? '9'} height={size ?? '16'} viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 1L1 8L8 15" stroke={color} strokeWidth="2" strokeMiterlimit="16" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
